import { $http } from "@/plugins/api";

const getDefaultAzure = () => {
  return {
    azure: [],
    azureAcount: null,
    azureSeats: null,
  };
};
const state = getDefaultAzure();

const actions = {
  clearAzureCache({ commit }) {
    commit("resetState");
  },
  async numberOfSeats({ commit }, organisation) {
    let response = null;
    if (organisation.azureID) {
      response = await $http.get(
        `${import.meta.env.VITE_API_CLIENT}/pt/org/${
          organisation.organisation
        }/seats/${organisation.azureID}`,
      );
    } else {
      response = [];
    }
    commit("azureSeats", response);
  },
  async getAzureAccount({ commit }, organisation) {
    const azureResponse = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/pt/org/${organisation.uuid}/azure/${
        organisation.tennetID
      }`,
    );
    commit("azureAccount", azureResponse.data);
  },
  async deleteAzureAccount({ commit }, organisation) {
    const azzureDelete = await $http.delete(
      `${import.meta.env.VITE_API_CLIENT}/pt/org/${organisation.azzureDeleteObject.uuid}/azure/${
        organisation.azzureDeleteObject.id
      }`,
    );
    commit("azureAccount", []);
    return azzureDelete;
  },
  async getAllAzureAccounts({ commit }, organisation) {
    const azureResponse = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/pt/org/${organisation}/azure`,
      { _hasCustomErrorHandling: true },
    );

    commit("azureAccounts", azureResponse.data);
    return azureResponse.data;
  },
};
const getters = {
  getAzureSeats: (state) => state.azureSeats,
  getAzureAccounts: (state) => state.azure,
  getazureAccount: (state) => state.azureAcount,
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultAzure());
  },
  azureSeats: (state, seats) => (state.azureSeats = seats.data),
  azureAccount: (state, account) => (state.azureAcount = account),
  resetAzureAccounts: (state) => (state.azure = []),
  azureAccounts(state, data) {
    state.azure = data;
    const idsSeen = new Set();
    const newArray = state.azure.filter((obj) => {
      if (!obj || obj === null || obj === undefined) {
        return false; // filter out null or undefined values
      }
      if (idsSeen.has(obj.id)) {
        return false; // filter out objects with duplicate ids
      }
      idsSeen.add(obj.id);
      return true;
    });

    state.azure = newArray;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
