import { $http } from "@/plugins/api";

import type { Commit } from "vuex";
import type { Campaign, CustomerCampaignSummary } from "./types";
import type { Collection, Params } from "@/types/api";

interface State {
  campaigns: Collection<Campaign>;
  customerCampaignSummary: CustomerCampaignSummary | null;
}

const state: State = {
  campaigns: {
    current_page: 1,
    page_size: 5,
    results: [],
    total: 0,
  },
  customerCampaignSummary: null,
};

export const getters = {
  campaigns: (state: State) => state.campaigns,
};

export const actions = {
  async getCampaigns(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      params,
    }: {
      globalOrganisationID: string | null;
      params: Params;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const reqParams: Params = {};

    for (const [key, value] of Object.entries(params)) {
      reqParams[key] = value.toString().toLocaleLowerCase();
    }

    const searchParams = new URLSearchParams(reqParams);

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/campaigns?${searchParams.toString()}`,
    );

    commit("setCampaigns", res.data);
  },
  async getContinuousCampaigns(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
    }: {
      globalOrganisationID: string | null;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const params = {
      // Continuous Campaigns have no 'end_date'
      sortKey: "end_date",
      results: "1",
    };

    const reqParams: Params = {};

    for (const [key, value] of Object.entries(params)) {
      reqParams[key] = value.toString().toLocaleLowerCase();
    }

    const searchParams = new URLSearchParams(reqParams);

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/campaigns?${searchParams.toString()}`,
    );

    return res.data.results || [];
  },
  async createCampaign(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      campaign,
    }: {
      globalOrganisationID: string | null;
      campaign: Partial<Campaign>;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/campaigns`,
      campaign,
    );
  },
  async enroll(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      campaignUUID,
      customers,
    }: {
      globalOrganisationID: string | null;
      campaignUUID: string | null;
      customers: string[];
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    if (campaignUUID === null) {
      throw new Error("Campaign ID is not provided");
    }

    await $http.put(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/campaigns/${campaignUUID}/enroll`,
      {
        customers,
      },
    );
  },
  async batchDelete(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      ids,
    }: { globalOrganisationID: string | null; ids: string[] },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/campaigns/delete`,
      ids,
    );
  },
  async getCustomerCampaignSummary(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      campaignUuid,
      customerUuid,
    }: {
      globalOrganisationID: string | null;
      campaignUuid: string;
      customerUuid: string;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/campaigns/${campaignUuid}/${customerUuid}`,
    );

    commit("setCustomerCampaignSummary", res.data);
  },
};

const mutations = {
  setCampaigns: (state: State, data: Collection<Campaign>) =>
    (state.campaigns = data),
  setCustomerCampaignSummary: (state: State, data: CustomerCampaignSummary) =>
    (state.customerCampaignSummary = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
