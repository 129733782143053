import { v4 as uuid } from "uuid";

const state = {
  alert: null,
  timeoutID: null,
};

const getters = {
  alert: (state) => state.alert,
};

const actions = {
  displayAlert({ commit }, alert) {
    alert.id = uuid();

    commit("showAlert", alert);

    alert.timeoutID = alert.duration
      ? setTimeout(() => {
          commit("closeAlert", alert);
        }, alert.duration)
      : null;

    commit("updateTimeoutID", alert);
  },

  closeAlert({ commit, state }, alert) {
    state.timeoutID && clearTimeout(state.timeoutID);

    commit("closeAlert", alert);
  },
};

const mutations = {
  showAlert: (state, alert) => {
    state.alert = alert;
  },
  closeAlert: (state) => {
    state.alert = null;
  },
  updateTimeoutID: (state, alert) => (state.timeoutID = alert.timeoutID),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
