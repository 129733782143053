<template>
  <div>
    <div v-if="!loading">
      <cl-sidebar-dropdown
        :is-closed="isClosed"
        :test-id="dataTestId"
        @on-dropdown="resetList"
      >
        <template #title>
          <div class="tw-flex tw-items-center tw-gap-4">
            <div
              class="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-[#219ad3] tw-p-1"
            >
              <component :is="icon" />
            </div>
            <div v-if="!isClosed">{{ selected ? selected.name : "" }}</div>
          </div>
        </template>
        <template #search>
          <cl-sidebar-dropdown-search @search="debounceFilter" />
        </template>
        <template #menu>
          <template v-if="items.length > 0">
            <cl-sidebar-dropdown-menu-item
              v-for="item in items"
              :key="item.name"
              :data-test-id="`${dataTestId}-${item.name}`"
              class="tw-cursor-pointer tw-py-4"
              @on-click="setSelected(item)"
            >
              {{ item.name }}
            </cl-sidebar-dropdown-menu-item>
          </template>
          <template v-else>
            <li class="tw-px-6 tw-py-4">{{ $t("No results found") }}</li>
          </template>
        </template>
      </cl-sidebar-dropdown>
    </div>
    <cl-skeleton v-else>
      <cl-skeleton-row />
      <cl-skeleton-row />
    </cl-skeleton>
  </div>
</template>

<script>
import { ref, watch, toRefs } from "vue";
import { refDebounced, useDebounceFn } from "@vueuse/core";

export default {
  props: {
    isClosed: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    initialSelected: {
      type: [String, Number],
      default: "",
    },
    dataTestId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "cl-icon-tabler-icon-building-skyscraper",
    },
  },
  emits: ["selected", "search", "resetList"],
  setup(props, { emit }) {
    const { options: items } = toRefs(props);
    const debounceFilter = useDebounceFn((string) => {
      emit("search", string);
    }, 500);

    const search = ref("");
    const searchDebounced = refDebounced(search, 300);
    const selected = ref(null);

    watch(
      () => props.options,
      (newOptions) => {
        selected.value = props.initialSelected
          ? newOptions.find((item) => item.id == props.initialSelected)
          : newOptions[0];
      },
      { deep: true, immediate: true },
    );
    watch(searchDebounced, (newSearch) => {
      emit("search", newSearch);
    });

    const resetList = () => {
      emit("resetList");
    };

    const setSelected = (selectedOption) => {
      selected.value = selectedOption;
      emit("selected", selected.value);
    };

    return {
      debounceFilter,
      items,
      search,
      searchDebounced,
      selected,
      resetList,
      setSelected,
    };
  },
};
</script>
