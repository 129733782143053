/* c8 ignore start */
import { i18n } from "@/i18n.js";
import { SIDENAV_GROUP_BY } from "@/constants";
import { PHISHING_SIMULATION } from "@/utils/services";

export const MSP_NAV_ITEMS = [
  {
    order: 2,
    groupBy: SIDENAV_GROUP_BY.BETA,
    label: i18n.global.t("Phishing Simulation"),
    icon: "cl-icon-tabler-fish-hook",
    id: "/msp/phishing_simulation",
    dataTestId: "msp-phishing_simulation",
    solutionId: PHISHING_SIMULATION,
    permanentPill: { variant: "success", text: i18n.global.t("New") },
    children: [
      {
        label: i18n.global.t("Overview"),
        id: "/msp/phishing_simulation/overview",
        dataTestId: "msp-phishing_simulation-overview",
      },
      {
        label: i18n.global.t("Phishing Campaigns"),
        id: "/msp/phishing_simulation/phishing_campaigns",
        dataTestId: "msp-phishing_simulation-phishing_campaigns",
      },
    ],
  },
];

export const CUSTOMER_NAV_ITEMS = [
  {
    order: 2,
    groupBy: SIDENAV_GROUP_BY.BETA,
    label: i18n.global.t("Phishing Simulation"),
    icon: "cl-icon-tabler-fish-hook",
    id: "/customer/phishing_simulation",
    dataTestId: "customer-phishing_simulation",
    solutionId: PHISHING_SIMULATION,
    permanentPill: { variant: "success", text: i18n.global.t("New") },
    children: [
      {
        label: i18n.global.t("Overview"),
        id: "/customer/phishing_simulation/overview",
        dataTestId: "customer-phishing_simulation-overview",
      },
      {
        label: i18n.global.t("Phishing Campaigns"),
        id: "/customer/phishing_simulation/phishing_campaigns",
        dataTestId: "customer-phishing_simulation-phishing_campaigns",
        disabled: true,
      },
    ],
  },
];
/* c8 ignore end */
