import { $http } from "@/plugins/api";
import type { Commit } from "vuex";
import { GetterTree, MutationTree } from "vuex";
import {
  IndividualLicence,
  History,
  Data,
  GraphDataType,
  OrgID,
  ApiLicence,
  LicenseResponse,
  Collection,
  DeleteLicence,
} from "./type";

interface LicenceData {
  collection: LicenseResponse | null;
  children: Collection | null;
  data: Data[] | null;
  total: number | null;
  available: number | null;
  history: History[] | null;
  individualLicence: IndividualLicence | null;
  graph: GraphDataType | null;
}

interface LicenceState {
  licence: LicenceData;
}

const getDefaultLicence = (): LicenceState => {
  return {
    licence: {
      data: null,
      total: null,
      available: null,
      history: null,
      individualLicence: null,
      graph: null,
      collection: null,
      children: null,
    },
  };
};
const state: LicenceState = getDefaultLicence();
const getters: GetterTree<LicenceState, Data> = {
  getListOfLicences: (state) => state.licence.data?.slice() || [],
  getListOfLicencesByChildren: (state) => state.licence.data?.slice() || [],
  getAvailableLicences: (state) => state.licence.available,
  getMaxLicences: (state) => state.licence.total,
  getLicenceHistoryStats: (state) => state.licence.history,
  getLicenceGraph: (state) => state.licence.graph?.data,
};

const actions = {
  async clearLicenceCache({ commit }: { commit: Commit }) {
    commit("resetState");
  },
  async getLicenceHistory({ commit }: { commit: Commit }, { uuid }: OrgID) {
    const today = new Date();
    today.setMonth(today.getMonth() - 12);
    const dateTo = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;
    const prevMonths = `${today.toISOString().slice(0, 7)}`;
    const service = import.meta.env.VITE_PHISH_ORG_SERVICE;
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/licence/organisations/${uuid}/licences/${service}/history/graph?date_from=${prevMonths}&date_to=${dateTo}`,
    );
    commit(`setLicenceGraph`, res.data);
  },
  async createLicence(
    { commit }: { commit: Commit },
    { service_uuid, uuid, formData }: ApiLicence,
  ) {
    const seats_purchased = formData?.seats_purchased
      ? Math.trunc(+formData.seats_purchased)
      : undefined;
    const res = await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/licence/organisations/${uuid}/licences`,
      {
        service_uuid: service_uuid,
        seats_purchased: seats_purchased,
      },
    );
    commit("addLicence", res.data);
  },
  async removeLicence(
    { commit }: { commit: Commit },
    { service_uuid, uuid }: DeleteLicence,
  ) {
    const response = await $http.delete(
      `${import.meta.env.VITE_API_CLIENT}/licence/organisations/${uuid}/licences/${service_uuid}`,
    );
    commit("removeAlicence", response.data);
  },
  async updateLicence(
    { commit }: { commit: Commit },
    { service_uuid, uuid, formData }: ApiLicence,
  ) {
    const seats_purchased = formData?.seats_purchased
      ? Math.trunc(+formData.seats_purchased)
      : undefined;
    const res = await $http.patch(
      `${
        import.meta.env.VITE_API_CLIENT
      }/licence/organisations/${uuid}/licences/${service_uuid}`,
      {
        service_uuid: import.meta.env.VITE_PHISH_SERVICE,
        seats_purchased: seats_purchased,
      },
    );
    commit("updateLicences", res.data);
  },
  async getLicencesStats(
    { commit }: { commit: Commit },
    { service_uuid, uuid }: ApiLicence,
  ) {
    let responce = null;
    responce = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/licence/organisations/${uuid}/licences/${service_uuid}`,
    );

    const seats_purchased = responce?.data?.seats_purchased;
    const seats_used = responce?.data?.seats_used;
    const available = seats_purchased - seats_used;
    commit(`addTotalAmount`, seats_purchased);
    commit(`addTotalAvailable`, available);
  },
  async listAllLicences({ commit }: { commit: Commit }, { uuid }: OrgID) {
    const licences = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/licence/organisations/${uuid}/licences`,
    );
    commit(`listOfLicence`, licences.data);
    return licences.data;
  },
  async listOfLicencesChildren(
    { commit }: { commit: Commit },
    { uuid }: OrgID,
  ) {
    const licences = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/licence/organisations/${uuid}/licences?children=1`,
    );
    commit(`listOfLicenceChildren`, licences.data);
    return licences.data;
  },
};

const mutations: MutationTree<LicenceState> = {
  resetState(state) {
    Object.assign(state, getDefaultLicence());
  },
  listOfLicence: (state, data) => (state.licence.collection = data),
  listOfLicenceChildren: (state, data) => (state.licence.children = data),
  setLicenceGraph: (state, data) => (state.licence.graph = data),
  addLicence: (state, data) => (state.licence.data = data),
  addTotalAmount: (state, data) => (state.licence.total = data),
  addTotalAvailable: (state, data) => (state.licence.available = data),
  removeAlicence(state, data) {
    state.licence.collection?.data.filter(
      (item) => item.service.uuid !== data.service_uuid,
    );
  },
  updateLicences(state, data) {
    if (state.licence.data) {
      state.licence.data = state.licence.data.map((obj) =>
        obj.uuid === data.uuid ? data : obj,
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
