import { computed } from "vue";
import { useStore } from "vuex";
import { createGlobalState } from "@vueuse/core";

import type { Solution } from "@/store/types";

export const useEnabledSolutions = createGlobalState(() => {
  const store = useStore();

  const enabledSolutions = computed<string[]>(() =>
    store.state.solutions.solutions.map(
      (solution: Solution) => solution.service.uuid,
    ),
  );

  return { enabledSolutions };
});

export default {};
