<template>
  <div class="app">
    <cl-sidebar>
      <template #company-logo>
        <img class="tw-relative tw-right-4" alt="site logo" :src="uiLogo" />
      </template>
      <template #dropdown-select="{ isClosed }">
        <t-nav-dropdown
          data-test-id="navbar-msp-select"
          :loading="loading"
          :is-closed="isClosed"
          :options="mspAccountList"
          :initial-selected="initialMsp"
          @reset-list="setDropDownList"
          @search="itemBeingSearched"
          @selected="mspSelected"
        />
      </template>
      <template #side-nav="{ isClosed: isSidebarCollapsed }">
        <t-side-nav
          :nav-menu-set-up="systemNavMenuSetUp"
          :is-sidebar-collapsed="isSidebarCollapsed"
          :tier="tier"
        ></t-side-nav>
      </template>
    </cl-sidebar>
    <div class="app-content">
      <main
        class="tw-flex-1 tw-overflow-y-auto tw-bg-light tw-p-4 max-lg:tw-ml-14"
      >
        <div class="tw-w-full">
          <t-page-header
            :product-links="productLinks"
            :user-links="userLinks"
            :data-test-id="'header-user-menu'"
          />
          <router-view />
        </div>
      </main>
      <cl-footer />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TPageHeader from "@/components/TPageHeader";
import TNavDropdown from "@/components/TNavDropdown";
import TSideNav from "@/components/TSideNav";
import { usePrimaryNavItems } from "@/composables";
import { NAV_ITEMS_MODE, TIER } from "@/constants";

export default {
  name: "SystemLayout",
  components: {
    TNavDropdown,
    TPageHeader,
    TSideNav,
  },
  setup() {
    const { navItems } = usePrimaryNavItems();
    const systemNavItems = navItems.get(NAV_ITEMS_MODE.SYSTEM);
    return {
      systemNavItems,
    };
  },
  data() {
    return {
      uiLogo: this.$getImageUrl("assets/branding/Titan_Logo.svg"),
      loading: false,
      mspAccountList: null,
      initialMsp: null,
      tier: TIER.system,
      params: {
        filter: "",
        results: 20,
      },
      options: [
        {
          name: "All Customers",
          short: "AC",
          id: 0,
          allCustomers: true,
          placeholder: "Search Customers",
        },
        {
          name: "All MSPs",
          short: "AM",
          allMsps: true,
          allCustomers: false,
          placeholder: "Search MSPs",
        },
        {
          object: "user",
          id: 1,
          created: "2022-04-14 09:24:29",
          accountname: "Customer with a huge name just for testing",
          description: "Customer",
          timezone: "UTC",
          email: "customer@titanhq.com",
          license: "customer01",
          lastlogin: "2022-04-20 14:45:32",
          install_key: "22b53996-cbc6-483a-bcbd-484cf37a0c02",
          enabled_2fa: false,
          suspended: false,
          name: "Customer with a huge name just for testing",
          testID:
            "navbar-customer-select-customer-with-a-huge-name-just-for-testing",
          allCustomers: false,
          short: "Cw",
        },
      ],
      productLinks: [
        {
          title: "ArcTitan",
          link: "https://www.titanhq.com/arctitan-customer-signup?from_product=webtitan&to_product=arctitan",
          active: false,
          description: this.$t("Security Archiving Solution"),
          icon: this.$getImageUrl("assets/branding/ArcTitan.svg"),
        },
        {
          title: "SafeTitan",
          link: "https://www.titanhq.com/safetitan-customer-signup?from_product=spamtitan&to_product=safetitan",
          active: false,
          description: this.$t("Security Awareness Training"),
          icon: this.$getImageUrl("assets/branding/SafeTitan.png"),
        },
        {
          title: "SpamTitan",
          link: "https://www.titanhq.com/spamtitan-customer-signup?from_product=webtitan&to_product=spamtitan",
          active: false,
          description: this.$t("Email Anti-Spam Solution"),
          icon: this.$getImageUrl("assets/branding/SpamTitan.svg"),
        },
        {
          title: "WebTitan",
          link: "https://www.titanhq.com/webtitan-customer-signup?from_product=webtitan&to_product=webtitan",
          active: false,
          description: this.$t("DNS Filtering"),
          icon: this.$getImageUrl("assets/branding/WebTitan.svg"),
        },
      ],
      userLinks: [],
    };
  },
  computed: {
    ...mapGetters("dropDown", ["dropDownList"]),
    ...mapGetters("authentication", ["authUser"]),
    systemNavMenuSetUp() {
      return this.separatedByGroup(this.systemNavItems);
    },
  },
  watch: {
    dropDownList: {
      handler() {
        this.mspAccountList = this.cleanMspAccounts(this.dropDownList);
      },
      deep: true,
      immediate: true,
    },
  },
  beforeCreate() {
    const userToken = this.$userPrefs.getCurrentToken();
    if (!userToken) this.$router.push("/");
  },
  async created() {
    this.setSupportLinks();
  },
  beforeMount() {
    this.setParentPath("");
    this.setMspUser({
      name: "All MSPs",
      short: "AC",
      id: 0,
      AllMsps: true,
      license: "ABC-123",
      placeholder: "Search MSPs",
    });
  },
  methods: {
    ...mapActions("pageMeta", ["setParentPath"]),
    ...mapActions("dropDown", ["getDropDownList"]),
    ...mapActions("authentication", ["setMspUser"]),
    setSupportLinks() {
      const id = this.authUser?.uuid;
      this.userLinks = [
        {
          title: this.$t("Security"),
          link: `/profile/security?id=${id}`,
          target: "_blank",
          icon: "cl-icon-tabler-settings",
        },
      ];
    },
    async setDropDownList() {
      try {
        await this.getDropDownList({
          parent_uuid: null,
          params: this.params,
        });
      } finally {
        this.mspAccountList = this.cleanMspAccounts(this.dropDownList);
        this.loading = false;
      }
    },
    separatedByGroup(mspArray) {
      const mspByGroup = {};
      const alpha = [];

      mspArray.forEach((item) => {
        if ("groupBy" in item) {
          if (mspByGroup[item.groupBy]) {
            mspByGroup[item.groupBy].push(item);
          } else {
            mspByGroup[item.groupBy] = [item];
          }
        } else {
          alpha.push(item);
        }
      });

      return mspByGroup;
    },
    async itemBeingSearched(item) {
      try {
        const params = {
          filter: item,
          results: 20,
          page: 1,
        };
        await this.getDropDownList({
          parent_uuid: null,
          params: params,
        });
      } finally {
        this.loading = false;
      }
    },
    async mspSelected(msp) {
      this.setMspUser(msp);
      if (msp.AllMsps) {
        this.$router.push("/system/dashboard").catch(() => {});
      } else if (
        this.$route.path !== "/msp/customers" ||
        this.$route.path !== "/msp/overview"
      ) {
        this.$router.push({ name: "mspOverview" }).catch(() => {});
      }
    },
    cleanMspAccounts(accounts) {
      const allAccounts = accounts
        .map((acct) => {
          return {
            ...acct,
            name: acct.name || "",
            testID: `navbar-customer-select-${acct.name
              .toLowerCase()
              .replace(/\s/g, "-")}`,
            AllMsps: false,
            allCustomers: false,
            short: this.$getAccountShortName(acct.name),
          };
        })
        .filter((res) => res.organisation_type === "MSP")
        .sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0,
        );

      return [
        {
          name: "All MSPs",
          short: "AM",
          id: 0,
          AllMsps: true,
          allCustomers: false,
          placeholder: "Search MSPs",
        },
        ...allAccounts,
      ];
    },
  },
};
</script>
