<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style></style>
