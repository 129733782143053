<template>
  <div class="app">
    <cl-sidebar>
      <template #company-logo>
        <img class="tw-relative tw-right-4" alt="site logo" :src="uiLogo" />
      </template>

      <template #side-nav="{ isClosed: isSidebarCollapsed }">
        <t-side-nav
          :nav-menu-set-up="usePrimaryNavItems"
          :is-sidebar-collapsed="isSidebarCollapsed"
          :tier="tier"
        ></t-side-nav>
      </template>
    </cl-sidebar>
    <div class="app-content">
      <main
        class="tw-overflow-y-aut tw-flex-1 tw-bg-light tw-p-4 max-lg:tw-ml-14"
      >
        <div class="tw-w-full">
          <t-page-header
            :product-links="productLinks"
            :user-links="userLinks"
            :data-test-id="'header-user-menu'"
          />
          <router-view />
        </div>
      </main>
      <cl-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TPageHeader from "@/components/TPageHeader";

import { usePrimaryNavItems } from "@/composables";

import { NAV_ITEMS_MODE } from "@/constants";
import TSideNav from "@/components/TSideNav";
import { TIER } from "@/constants";

export default {
  name: "ProfileLayout",
  components: {
    TPageHeader,
    TSideNav,
  },
  setup() {
    const { navItems } = usePrimaryNavItems();
    const profileNavItems = navItems.get(NAV_ITEMS_MODE.PROFILE);

    return {
      profileNavItems,
    };
  },
  data() {
    return {
      uiLogo: this.$getImageUrl("assets/branding/Titan_Logo.svg"),
      productLinks: [],
      userLinks: [],
      tier: TIER.profile,
    };
  },
  computed: {
    ...mapGetters("authentication", ["authUser"]),
    usePrimaryNavItems() {
      return this.separatedByGroup(this.profileNavItems);
    },
    user() {
      const email = this.authUser.email ?? null;
      const accountname = this.authUser.accountname ?? null;

      return {
        email,
        accountname,
      };
    },
  },
  methods: {
    separatedByGroup(mspArray) {
      const mspByGroup = {};
      const alpha = [];

      mspArray.forEach((item) => {
        if ("groupBy" in item) {
          if (mspByGroup[item.groupBy]) {
            mspByGroup[item.groupBy].push(item);
          } else {
            mspByGroup[item.groupBy] = [item];
          }
        } else {
          alpha.push(item);
        }
      });

      return mspByGroup;
    },
  },
};
</script>
