import { USER_ROLES } from "@/constants";

const mspRoutes = [
  {
    path: "",
    redirect: "/msp/customers",
    component: () => import("@/views/Msp/App.vue"),
    exact: false,
    meta: {
      authorize: [USER_ROLES.SYSTEM, USER_ROLES.SALES, USER_ROLES.MSP],
    },
  },
  {
    path: "customers",
    exact: false,
    name: "mspOverview",
    component: () => import("@/modules/PhishTitan/views/Msp/Customers"),
  },
];

export default mspRoutes;
