import { $http } from "@/plugins/api";
import { ArctianAccount, ArctitanSender, Tag } from "@/types/arctitan";
import type { Commit } from "vuex";

interface ArctitanState {
  tag: Tag;
  arctianAccount: ArctianAccount;
}

const state: ArctitanState = {
  tag: {
    reserved: false,
    consumed: false,
  },
  arctianAccount: {
    name: "",
    email: "",
    country: "",
    uuid: "",
  },
};

const actions = {
  async hasTagMsp(
    { commit }: { commit: Commit },
    { formData, uuid }: ArctitanSender,
  ) {
    const tag = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/arctitan/msps/tag`,
      {
        mspName: formData.name,
        country: formData.country,
        mspOrganisationID: uuid,
      },
    );
    commit("reserveTag", tag.data);
  },
  async hasTagCustomer(
    { commit }: { commit: Commit },
    { formData, uuid }: ArctitanSender,
  ) {
    const tag = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/arctitan/msps/${uuid}/customers/tag`,
      {
        customerOrganisationID: formData.uuid,
        customerName: formData.name,
      },
    );
    commit("reserveTag", tag.data);
  },
  async createMspArctian(
    { commit }: { commit: Commit },
    { formData, uuid }: ArctitanSender,
  ) {
    const account = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/arctitan/msps`,
      {
        mspOrganisationID: uuid,
        mspName: formData.name,
        mspEmailAdmin: formData.email,
        country: formData.country,
      },
    );
    commit("arctianAccount", account.data);
  },

  async createCustomerArctian(
    { commit }: { commit: Commit },
    { formData, uuid }: ArctitanSender,
  ) {
    const account = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/arctitan/${uuid}/customers`,
      {
        customerOrganisationID: formData.uuid,
        customerEmailAdmin: formData.email,
        customerName: formData.name,
      },
    );
    commit("arctianAccount", account.data);
  },
};

const getters = {};

const mutations = {
  reserveTag: (state: ArctitanState, data: Tag) => (state.tag = data),
  arctianAccount: (state: ArctitanState, data: ArctianAccount) =>
    (state.arctianAccount = data),
};
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
