import { $http } from "@/plugins/api";

import type { Commit } from "vuex";

interface State {
  luresSent: number | null;
  luresClicked: number | null;
  protectedCustomers: number | null;
  atRiskCustomers: number | null;
  activeUsers: number | null;
  licensesIssued: number | null;
}
const defaultState = () => {
  return {
    luresSent: null,
    luresClicked: null,
    protectedCustomers: null,
    atRiskCustomers: null,
    activeUsers: null,
    licensesIssued: null,
  };
};

const state: State = defaultState();

export const getters = {
  luresSent: (state: State) => state.luresSent,
  luresClicked: (state: State) => state.luresClicked,
  protectedCustomers: (state: State) => state.protectedCustomers,
  atRiskCustomers: (state: State) => state.atRiskCustomers,
  activeUsers: (state: State) => state.activeUsers,
  licensesIssued: (state: State) => state.licensesIssued,
};

export const actions = {
  async getLuresSentAndClicked(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
    }: {
      globalOrganisationID: string;
    },
  ) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/cards/lures-sent`,
    );

    commit("setLuresSentAndClicked", res.data);
  },
  async getAtRiskAndProtected(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
    }: {
      globalOrganisationID: string;
    },
  ) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/cards/at-risk`,
    );

    commit("setAtRiskAndProtected", res.data);
  },
  async getActiveUsersAndLicensesIssued(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
    }: {
      globalOrganisationID: string;
    },
  ) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/cards/licenses`,
    );

    commit("setActiveUsersAndLicensesIssued", res.data);
  },
};

const mutations = {
  setLuresSentAndClicked: (
    state: State,
    {
      lures_sent,
      links_clicked,
    }: { lures_sent: number; links_clicked: number },
  ) => {
    state.luresClicked = links_clicked;
    state.luresSent = lures_sent;
  },
  setAtRiskAndProtected: (
    state: State,
    data: { protected: number; at_risk: number },
  ) => {
    state.atRiskCustomers = data.at_risk;
    state.protectedCustomers = data.protected;
  },
  setActiveUsersAndLicensesIssued: (
    state: State,
    data: { active_users: number; licenses_issued: number },
  ) => {
    state.activeUsers = data.active_users;
    state.licensesIssued = data.licenses_issued;
  },
  resetCards: (state: State) => Object.assign(state, defaultState()),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
