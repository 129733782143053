import { computed } from "vue";
import {
  FLAGS_CONFIG,
  FlagName,
  PRODUCTION_ENVS,
  TEST_DEV_ENV_MODE,
  TEST_STAGING_ENV_MODE,
  DEV_ENV_MODE,
} from "./constants/flagsConfig";

export const useFlag = (flagName: FlagName) => {
  const feature = FLAGS_CONFIG[flagName];
  const envMode = import.meta.env.VITE_ENV_MODE;

  const isFeatureAvailable = computed<boolean>(() => {
    if (PRODUCTION_ENVS.includes(envMode)) {
      return feature.prod;
    }
    if (envMode === TEST_STAGING_ENV_MODE) {
      return feature.testStaging;
    }
    if (envMode === TEST_DEV_ENV_MODE) {
      return feature.testDev;
    }
    if (envMode === DEV_ENV_MODE) {
      return feature.dev;
    }
    return false;
  });

  return { isFeatureAvailable };
};
