import { v4 as uuid } from "uuid";

const state = {
  toasts: [],
  timeoutIDs: {},
};

const getters = {
  toasts: (state) => state.toasts,
};

const actions = {
  displayToast({ commit }, toast) {
    toast.id = uuid();

    commit("showToast", toast);
    toast.timeoutID = toast.duration
      ? setTimeout(() => {
          commit("closeToast", toast);
        }, toast.duration)
      : null;

    commit("updateTimeoutID", toast);
  },

  closeToast({ commit, state }, toast) {
    state.timeoutIDs[`${toast.id}`] && clearTimeout(state.timeoutIDs[toast.id]);

    commit("closeToast", toast);
  },
};

const mutations = {
  showToast: (state, toast) => {
    state.toasts.push(toast);
  },
  closeToast: (state, toast) => {
    state.toasts = state.toasts.filter((t) => t.id !== toast.id);
    delete state.timeoutIDs[`${toast.id}`];
  },
  updateTimeoutID: (state, toast) =>
    (state.timeoutIDs[`${toast.id}`] = toast.timeoutID),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
