<template>
  <div class="tw-my-6 tw-flex tw-justify-between">
    <cl-form-input
      id="user_uuid"
      type="hidden"
      :value="uuid"
      test-id="profile-user-uuid"
    />
    <div>
      <cl-breadcrumbs
        :page-meta="pageMeta"
        :route-path="$route.path"
        class="tw-mb-4"
        @on-click="goTo"
      />
      <cl-heading size="h1">
        {{ pageMeta.title }}
      </cl-heading>
    </div>
    <cl-header-dropdown :data-test-id="dataTestId">
      <template #title>
        <p class="tw-mb-0 tw-font-bold">{{ name }}</p>
        <p class="tw-mb-0 tw-text-sm">{{ email }}</p>
      </template>
      <template #products>
        <cl-header-dropdown-product
          v-for="product in productLinks"
          :key="product.title"
          :label="product.active ? '' : $t('Try Now')"
          :link="product.link"
          :data-test-id="`header-menu-product-${product.title.toLowerCase()}`"
        >
          <div class="tw-flex tw-gap-4">
            <img
              class="tw-relative tw-h-7 tw-w-7"
              :src="product.icon"
              :alt="product.description"
            />
            <div>
              <div class="tw-text-primary-800">
                {{ product.title }}
              </div>
              <div class="tw-text-primary-800 tw-text-sm tw-font-bold">
                {{ product.description }}
              </div>
            </div>
          </div>
        </cl-header-dropdown-product>
      </template>
      <template #appearance>
        <div
          class="tw-relative tw-flex tw-items-center tw-justify-between"
          @click.stop
        >
          <span>Theme:</span>
          <cl-form-radio-group
            button
            :options="themeOptions"
            :selected-value="userPrefferedTheme"
            @update:selected="(selected) => setUserPreferredTheme(selected)"
          />
        </div>
      </template>
      <template #links>
        <cl-header-dropdown-link
          v-for="link in userLinks"
          :key="link.link"
          :link="link.link"
          :label="link.title"
          :target="link.target"
          :data-test-id="`header-menu-link-${link.title.toLowerCase()}`"
        >
          <component :is="link.icon" :width="'18'" :height="'18'" />
        </cl-header-dropdown-link>
        <router-link v-slot="{ href }" :to="{ name: 'logout' }">
          <cl-header-dropdown-link
            :link="href"
            :label="$t('Logout')"
            data-test-id="user-menu-logout"
          >
            <cl-icon-tabler-logout :width="'18'" :height="'18'" />
          </cl-header-dropdown-link>
        </router-link>
      </template>
    </cl-header-dropdown>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { useTheme } from "@/composables";

import { THEME } from "@/constants";

export default {
  props: {
    userLinks: {
      type: Array,
      default() {
        return [];
      },
    },
    productLinks: {
      type: Array,
      default() {
        return [];
      },
    },
    dataTestId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  setup() {
    const { userPrefferedTheme, setUserPreferredTheme } = useTheme();

    return {
      userPrefferedTheme,
      setUserPreferredTheme,
      themeOptions: [
        { label: "Dark", value: THEME.DARK },
        { label: "Light", value: THEME.LIGHT },
        { label: "System", value: THEME.SYSTEM },
      ],
    };
  },
  computed: {
    ...mapGetters("pageMeta", ["pageMeta"]),
    ...mapGetters("authentication", [
      "authUser",
      "selectedAccount",
      "selectedCustomer",
    ]),
    name() {
      return this.authUser?.accountname;
    },
    email() {
      return this.authUser?.email;
    },
    uuid() {
      return this.authUser?.uuid;
    },
  },
  methods: {
    getRegex(accountType) {
      switch (accountType) {
        case "System":
          return this.selectedCustomer ? /^\/system\/msp\// : /^\/system\//;
        case "MSP":
          return this.selectedCustomer ? /^\/msp\// : /^\/system\/msp\//;
        case "Customer":
          return this.selectedCustomer ? /^\/msp\// : null;
        default:
          return null;
      }
    },
    goTo(link) {
      const regex = this.getRegex(this.selectedAccount.type_name);
      const updatedRegex = link.replace(regex, "/");
      this.$router.push(updatedRegex).catch((err) => err);
    },
  },
};
</script>
