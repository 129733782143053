import { $http } from "@/plugins/api";

import type { Commit } from "vuex";
import type { LuresSentVsClicked, LureSchedule } from "./types";
import type { Collection, Params } from "@/types/api";

interface State {
  luresSentVsClicked: LuresSentVsClicked[];
  lureSchedules: Collection<LureSchedule>;
}

const state: State = {
  luresSentVsClicked: [],
  lureSchedules: {
    current_page: 1,
    page_size: 5,
    results: [],
    total: 0,
  },
};

const getters = {
  luresSentVsClicked: (state: State) => state.luresSentVsClicked,
  lureSchedules: (state: State) => state.lureSchedules,
};

const actions = {
  async getLuresSentVsClicked(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
    }: { globalOrganisationID: string | null; results: number },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/stats/lures`,
    );

    commit("setLuresSentVsClicked", res.data);
  },
  async sendTestLure(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      email,
    }: { globalOrganisationID: string | null; email: string },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    if (!email) {
      throw new Error("Email is not provided");
    }

    await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/campaigns/send-test-lure/${email}`,
      {},
    );
  },
  async getLureSchedules(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      campaignUuid,
      customerUuid,
      params,
    }: {
      globalOrganisationID: string;
      campaignUuid: string;
      customerUuid: string;
      params: Params;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const reqParams: Params = {};

    for (const [key, value] of Object.entries(params)) {
      reqParams[key] = value.toString().toLocaleLowerCase();
    }

    const searchParams = new URLSearchParams(reqParams);

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/campaigns/${campaignUuid}/${customerUuid}/schedules?${searchParams.toString()}`,
    );

    commit("setLureSchedules", res.data);
  },
};

const mutations = {
  setLuresSentVsClicked: (state: State, data: LuresSentVsClicked[]) =>
    (state.luresSentVsClicked = data),
  setLureSchedules: (state: State, data: Collection<LureSchedule>) =>
    (state.lureSchedules = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
