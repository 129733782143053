import { $http } from "@/plugins/api";

import type { Commit } from "vuex";
import type { Collection, Params } from "@/types/api";
import type { Course } from "./types";

interface State {
  courses: Collection<Course>;
}

const state: State = {
  courses: {
    current_page: 1,
    page_size: 5,
    results: [],
    total: 0,
  },
};

export const getters = {
  courses: (state: State) => state.courses,
};
export const actions = {
  async getCourses(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      params,
    }: {
      globalOrganisationID: string | null;
      params: Params;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const reqParams: Params = {};

    for (const [key, value] of Object.entries(params)) {
      reqParams[key] = value.toString().toLocaleLowerCase();
    }

    const searchParams = new URLSearchParams(reqParams);
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/sat/${globalOrganisationID}/courses?${searchParams.toString()}`,
    );

    commit("setCourses", res.data);
  },
};
export const mutations = {
  setCourses: (state: State, data: Collection<Course>) =>
    (state.courses = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
