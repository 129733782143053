import { $http } from "@/plugins/api";
import { Commit } from "vuex";

interface AntiSpoofingSettings {
  status: {
    value: boolean;
    inherited_from: string | null;
  };
}

interface AntiSpoofingState {
  settings?: AntiSpoofingSettings;
}

const state: AntiSpoofingState = {
  settings: undefined,
};

const getters = {
  settingsStatus: (state: AntiSpoofingState) => state.settings?.status.value,
};

const actions = {
  async getAntiSpoofingSettings(
    { commit }: { commit: Commit },
    {
      organisationID,
    }: {
      organisationID: string;
    },
  ) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/settings/${organisationID}/antispoofing`,
    );

    commit("setAntiSpoofingSettings", res.data);
  },
  async putAntiSpoofingSettings(
    { commit }: { commit: Commit },
    {
      organisationID,
      status,
    }: {
      organisationID: string;
      status: boolean;
    },
  ) {
    const res = await $http.put(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/settings/${organisationID}/antispoofing`,
      {
        status,
      },
      { _hasCustomErrorHandling: true },
    );

    commit("setAntiSpoofingSettings", res.data);
  },
};

const mutations = {
  setAntiSpoofingSettings: (
    state: AntiSpoofingState,
    data: AntiSpoofingSettings,
  ) => (state.settings = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
