import { $http } from "@/plugins/api";

const state = {
  title: null,
  subTitle: null,
  mspName: "msp",
  customerName: "customer",
  domainName: "domain",
  userName: "user",
  parentPath: "",
  dropDown: null,
};

const getters = {
  pageMeta: (state) => state,
};

const actions = {
  setPageTitle({ commit }, title) {
    document.title = `${title}`;
    commit("setPageTitle", title);
  },
  async getOrginisationsList({ commit }, { parent_uuid }) {
    const url = parent_uuid
      ? `/organisations?parent_uuid=${parent_uuid}`
      : `/organisations`;
    const orgsResponse = await $http.get(url);

    commit("addDropDown", orgsResponse.data.data);
  },
  /**
   * @param commit
   * @param {string[]} subTitle Array of subtitles, each set to separate p tag
   */
  setPageSubtitle({ commit }, subTitle) {
    commit("setPageSubtitle", subTitle);
  },
  setParentPath({ commit }, path) {
    commit("setParentPath", path);
  },

  setMSP({ commit }, name) {
    commit("setMSP", name);
    commit("setDomain", "domain");
    commit("setUser", "user");
  },
  setCustomerName({ commit }, name) {
    commit("setCustomer", name);
    commit("setDomain", "domain");
    commit("setUser", "user");
  },
  setDomain({ commit }, name) {
    commit("setDomain", name);
    commit("setUser", "user");
  },
  setUser({ commit }, name) {
    commit("setUser", name);
  },
};

const mutations = {
  setPageTitle: (state, title) => (state.title = title),
  setPageSubtitle: (state, subTitle) => (state.subTitle = subTitle),
  setMSP: (state, name) => (state.mspName = name),
  setCustomer: (state, name) => (state.customerName = name),
  setDomain: (state, name) => (state.domainName = name),
  setUser: (state, name) => (state.userName = name),
  setParentPath: (state, path) => (state.parentPath = path),
  addDropDown: (state, list) => (state.dropDown = list),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
