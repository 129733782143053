<template>
  <div class="app">
    <cl-sidebar>
      <template #company-logo>
        <img class="tw-relative tw-right-4" alt="site logo" :src="uiLogo" />
      </template>
      <template #dropdown-select="{ isClosed }">
        <t-nav-dropdown
          v-if="
            selectedAccount.type_name === 'System' ||
            selectedAccount.type_name === 'MSP'
          "
          icon="cl-tabler-icon-users"
          data-test-id="navbar-customer-select"
          :loading="false"
          :is-closed="isClosed"
          :options="userAccountList"
          :initial-selected="initialMsp"
          @reset-list="setDropDownList"
          @search="itemBeingSearched"
          @selected="customerSelected"
        />
      </template>
      <template #side-nav="{ isClosed: isSidebarCollapsed }">
        <t-side-nav
          v-if="!loadingSolutions"
          :nav-menu-set-up="customerNavMenuSetUp"
          :is-sidebar-collapsed="isSidebarCollapsed"
          :tier="tier"
        ></t-side-nav>
      </template>
    </cl-sidebar>
    <div class="app-content">
      <main
        class="tw-flex-1 tw-overflow-y-auto tw-bg-light tw-p-4 max-lg:tw-ml-14"
      >
        <ms-banner v-if="isBanner & !getAzureAccounts.length"></ms-banner>

        <div class="tw-w-full">
          <t-page-header
            :product-links="productLinks"
            :user-links="userLinks"
            :data-test-id="'header-user-menu'"
          />
          <router-view />
        </div>
      </main>
      <cl-footer />
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
import { useRouter, useRoute } from "vue-router";

import { TIER } from "@/constants";

import msBanner from "@/views/Common/MicrosoftBanner";
import TPageHeader from "@/components/TPageHeader";
import TNavDropdown from "@/components/TNavDropdown";
import {
  newFeaturePageVisitedCookie,
  getSideNavItemPill,
} from "@/components/TNewFeaturesModal/utils/new-features-announcement.ts";
import { usePrimaryNavItems, useEnabledSolutions } from "@/composables";
import { useGlobalOrganisationID } from "@/composables/useGlobalOrganisationID";

import { NAV_ITEMS_MODE } from "@/constants";
import TSideNav from "@/components/TSideNav";

import {
  PHISHING_SIMULATION,
  ARC_SERVICE,
  PHISH_SERVICE,
  SAT_SERVICE,
} from "@/utils/services";
import { useFlag } from "@/composables/useFlags/useFlag";

export default {
  components: {
    msBanner,
    TNavDropdown,
    TPageHeader,
    TSideNav,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const { enabledSolutions } = useEnabledSolutions();
    const { customerGlobalOrganisationId } = useGlobalOrganisationID();
    const { navItems } = usePrimaryNavItems();
    const { isFeatureAvailable } = useFlag("FEATURE_TOUR");

    const customerAdminNavItems = navItems.get(NAV_ITEMS_MODE.CUSTOMER_ADMIN);
    const loadingSolutions = ref(true);

    const getSolutions = async (uuid) => {
      await store.dispatch("solutions/getSolutions", {
        uuid,
      });
    };

    onBeforeMount(async () => {
      try {
        loadingSolutions.value = true;

        store.dispatch("solutions/resetState");
        await getSolutions(customerGlobalOrganisationId.value);
      } finally {
        if (route.meta.solutionId) {
          let isSolutionDisabled = false;

          isSolutionDisabled = !enabledSolutions.value.some(
            (solution) => solution === route.meta.solutionId,
          );

          if (isSolutionDisabled) {
            await router.replace({ name: "customerUsageRoute" });
            router.go(0);
          }
        }

        loadingSolutions.value = false;
      }
    });

    return {
      loadingSolutions,
      enabledSolutions,
      customerAdminNavItems,
      isFeatureAvailable,
    };
  },
  data() {
    return {
      uiLogo: this.$getImageUrl("assets/branding/Titan_Logo.svg"),
      isBanner: false,
      initialCustomer: null,
      initialMsp: null,
      tier: TIER.customer,
      userAccountList: null,
      productLinks: [
        {
          title: "ArcTitan",
          link: "https://www.titanhq.com/arctitan-customer-signup?from_product=webtitan&to_product=arctitan",
          active: false,
          description: this.$t("Security Archiving Solution"),
          icon: this.$getImageUrl("assets/branding/ArcTitan.svg"),
        },
        {
          title: "SafeTitan",
          link: "https://www.titanhq.com/safetitan-customer-signup?from_product=spamtitan&to_product=safetitan",
          active: false,
          description: this.$t("Security Awareness Training"),
          icon: this.$getImageUrl("assets/branding/SafeTitan.png"),
        },
        {
          title: "SpamTitan",
          link: "https://www.titanhq.com/spamtitan-customer-signup?from_product=webtitan&to_product=spamtitan",
          active: false,
          description: this.$t("Email Anti-Spam Solution"),
          icon: this.$getImageUrl("assets/branding/SpamTitan.svg"),
        },
        {
          title: "WebTitan",
          link: "https://www.titanhq.com/webtitan-customer-signup?from_product=webtitan&to_product=webtitan",
          active: false,
          description: this.$t("DNS Filtering"),
          icon: this.$getImageUrl("assets/branding/WebTitan.svg"),
        },
      ],
      userLinks: [],
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "authUser",
      "selectedMsp",
      "selectedCustomer",
      "selectedAccount",
    ]),
    ...mapGetters("azure", ["getAzureSeats", "getAzureAccounts"]),
    ...mapGetters("dropDown", ["dropDownList"]),
    isPillVisible() {
      return this.isFeatureAvailable && !newFeaturePageVisitedCookie.value;
    },
    customerNavMenuSetUp() {
      const routePath = this.$route.path;
      const setIsActive = (link) => routePath.includes(link);

      const items = this.customerAdminNavItems
        .map((item) => {
          const isSolutionDisabled = ref(false);

          if (item.solutionId) {
            isSolutionDisabled.value = !this.enabledSolutions.some(
              (solution) => solution === item.solutionId,
            );
          }

          // PhS, ArcTitan if disabled should not be visible
          // https://gitlab.titanhq.com/titanhq/dev/scrum/team-sherman/-/issues/504
          if (isSolutionDisabled.value) {
            if (item.solutionId === ARC_SERVICE) {
              return;
            }

            if (item.solutionId === PHISHING_SIMULATION) {
              return;
            }

            if (item.solutionId === SAT_SERVICE) {
              return;
            }
          }

          return {
            ...item,
            disabled: isSolutionDisabled.value,
            pill:
              item.status && this.isPillVisible && !setIsActive(item.id)
                ? getSideNavItemPill(item.status)
                : "",
            children: item.children?.map((child) => ({
              ...child,
              disabled: isSolutionDisabled.value,
              pill:
                child.status && this.isPillVisible
                  ? getSideNavItemPill(child.status)
                  : "",
              children: child.children?.map((grandChild) => ({
                ...grandChild,
                disabled: isSolutionDisabled.value,
                pill:
                  grandChild.status && this.isPillVisible
                    ? getSideNavItemPill(grandChild.status)
                    : "",
              })),
            })),
          };
        })
        .filter(Boolean);

      return this.separatedByGroup(items);
    },
  },
  watch: {
    getAzureAccounts: {
      handler() {
        return this.getAzureAccounts;
      },
      deep: true,
    },
    dropDownList: {
      handler() {
        this.userAccountList = this.cleanAccounts(this.dropDownList);
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeMount() {
    let parentPath = "";
    if (this.selectedAccount?.type_name === "System") {
      parentPath += "/system/msp";
    }
    if (this.selectedAccount?.type_name === "MSP") {
      parentPath += "/msp";
    }
    this.setParentPath(parentPath);
    this.userAccountList = this.cleanAccounts(this.dropDownList);
    this.userAccountList[0].name = this.selectedCustomer?.name;
    this.setCustomerName(this.selectedCustomer?.name);
  },

  async created() {
    this.setSupportLinks();
    try {
      await this.getLicencesStats({
        uuid: this.selectedCustomer?.uuid || this.selectedAccount?.uuid,
        service_uuid: PHISH_SERVICE,
      });
      await this.getAllAzureAccounts(
        this.selectedCustomer?.uuid || this.selectedAccount.uuid,
      );
      if (this.getAzureAccounts.length > 0) {
        await this.numberOfSeats({
          organisation:
            this.selectedCustomer?.uuid || this.selectedAccount?.uuid,
          azureID: this.getAzureAccounts[0].id,
        });
      }
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
    } finally {
      this.isBanner = !this.getAzureAccounts.length;
    }
  },

  methods: {
    ...mapActions("pageMeta", [
      "setPageTitle",
      "setPageSubtitle",
      "setParentPath",
      "setCustomerName",
    ]),
    ...mapActions("dropDown", ["getDropDownList", "resetState"]),
    ...mapActions("licence", [
      "getLicences",
      "getLicencesStats",
      "getLicenceHistory",
    ]),
    ...mapActions("authentication", ["setCustomer"]),
    ...mapActions("azure", [
      "getAllAzureAccounts",
      "numberOfSeats",
      "clearAzureCache",
    ]),
    async customerSelected(customer) {
      if (customer.allCustomers) {
        this.$router.push("/msp/customers");
      } else {
        this.setCustomer(customer);
        this.setCustomerName(customer.name);
        this.clearAzureCache();
        window.location.reload();
      }
    },

    separatedByGroup(customerArray) {
      const customerByGroup = {};

      customerArray.forEach((item) => {
        if (customerByGroup[item.groupBy]) {
          customerByGroup[item.groupBy].push(item);
        } else {
          customerByGroup[item.groupBy] = [item];
        }
      });
      if (customerByGroup.alpha) {
        customerByGroup.alpha = [];
      }
      return customerByGroup;
    },
    setSupportLinks() {
      const id = this.authUser?.uuid;

      this.userLinks = [
        {
          title: this.$t("Support"),
          link: "https://webtitancloud.freshdesk.com/support/home",
          target: "_blank",
          icon: "cl-icon-tabler-lifebuoy",
        },
        {
          title: this.$t("Security"),
          link: `/profile/security?id=${id}`,
          target: "_blank",
          icon: "cl-icon-tabler-settings",
        },
      ];
    },
    async setDropDownList() {
      const params = {
        filter: "",
        results: 20,
      };
      try {
        this.resetState();
        await this.getDropDownList({
          globalOrganisationID:
            this.selectedMsp?.uuid || this.selectedAccount?.uuid,
          params: params,
        });
      } finally {
        this.userAccountList = this.cleanAccounts(this.dropDownList);
      }
    },
    async itemBeingSearched(item) {
      try {
        const params = {
          filter: item,
          results: 25,
        };
        this.resetState();
        await this.getDropDownList({
          globalOrganisationID:
            this.selectedMsp?.uuid || this.selectedAccount?.uuid,
          params: params,
        });
      } finally {
        this.loading = false;
      }
    },
    cleanAccounts(accounts) {
      const allAccounts = accounts
        .map((acct) => {
          return {
            ...acct,
            id: acct.uuid,
            name: acct.name || "",
            short: this.$getAccountShortName(acct.name || ""),
            active: false,
            allCustomers: false,
            placeholder: "Search Customers",
          };
        })
        .filter((res) => res.organisation_type === "Customer")
        .sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0,
        );
      let otherAccounts = [
        {
          name: "All Customers",
          short: "AM",
          allMsps: false,
          allCustomers: true,
          placeholder: "Search Customers",
        },
      ];

      return [...otherAccounts, ...allAccounts];
    },
  },
};
</script>
