/* c8 ignore start */
import { PHISHING_SIMULATION } from "@/utils/services";

export const customerRoutes = {
  path: "/customer",
  component: () => import("@/layouts/Customer/CustomerLayout.vue"),

  children: [
    {
      path: "phishing_simulation",
      name: "customer-phishing_simulation",
      component: () => import("@/modules/PhishingSimulations/views/Customer"),
      redirect: {
        name: "customer-phishing_simulation-overview",
      },
      meta: {
        solutionId: PHISHING_SIMULATION,
      },
      children: [
        {
          path: "overview",
          name: "customer-phishing_simulation-overview",
          component: () =>
            import("@/modules/PhishingSimulations/views/Customer/Overview"),
        },
        {
          path: "phishing_campaigns",
          name: "customer-phishing_simulation-phishing_campaigns",
          component: () =>
            import(
              "@/modules/PhishingSimulations/views/Customer/PhishingCampaigns"
            ),
        },
      ],
    },
  ],
};

export default {};
/* c8 ignore end */
