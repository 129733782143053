import { ref, watch } from "vue";
import { useDark, usePreferredDark } from "@vueuse/core";
import { useCookies } from "@vueuse/integrations/useCookies";

import { THEME } from "@/constants";

export const useTheme = () => {
  const THEME_KEY = "user-preferred-theme";

  const COOCKIE_SETTINGS = {
    path: "/", // Important for SPA
    maxAge: 365 * 86400, // 1 Day = 24 Hrs = 24*60*60 = 86400.
  };

  const cookies = useCookies();
  const isSystemPreferredDark = usePreferredDark();

  const userPrefferedTheme = ref(cookies.get(THEME_KEY) || THEME.SYSTEM);

  const isThemeDark = useDark({
    selector: "html",
    attribute: "data-theme",
    valueDark: "dark",
    valueLight: "light",
  });

  const setUserPreferredTheme = (theme) => {
    userPrefferedTheme.value = theme;
    cookies.set(THEME_KEY, theme, COOCKIE_SETTINGS);
    window.location.reload();
  };

  watch(
    () => userPrefferedTheme.value,
    () => {
      if (userPrefferedTheme.value === THEME.SYSTEM) {
        isThemeDark.value = isSystemPreferredDark.value;
      }

      if (userPrefferedTheme.value === THEME.DARK) {
        isThemeDark.value = true;
      }

      if (userPrefferedTheme.value === THEME.LIGHT) {
        isThemeDark.value = false;
      }
    },
    { immediate: true },
  );

  return {
    isThemeDark,
    userPrefferedTheme,
    setUserPreferredTheme,
  };
};

export default {};
