import { i18n } from "@/i18n";
import { SIDENAV_GROUP_BY } from "@/constants";
import { PHISH_SERVICE } from "@/utils/services";
import { NEW } from "@/types/side-nav";

export const MSP_NAV_ITEMS = [
  {
    order: 1,
    label: i18n.global.t("PhishTitan"),
    groupBy: SIDENAV_GROUP_BY.BETA,
    icon: "cl-icon-tabler-mail",
    id: "/msp/phish_titan",
    dataTestId: "msp-phish-titan",
    solutionId: PHISH_SERVICE,
    children: [
      {
        label: i18n.global.t("Overview"),
        id: "/msp/phish_titan/overview",
        dataTestId: "msp-phish_titan-overview",
      },
      {
        label: i18n.global.t("Incidents"),
        id: "/msp/phish_titan/incidents",
        dataTestId: "msp-phish_titan-incidents",
        permanentPill: { variant: "infoFull", text: i18n.global.t("AI") },
      },
      {
        status: NEW,
        label: i18n.global.t("Configuration"),
        id: "/msp/phish_titan/configuration",
        dataTestId: "msp-phish_titan-configuration",
        hasEvent: true,
        children: [
          {
            label: i18n.global.t("sidebar-mail-flow"),
            id: "/msp/phish_titan/configuration/mail_flow",
            dataTestId: "msp-phish_titan-configuration-mail-flow",
            status: NEW,
          },
          {
            label: i18n.global.t("page-title-link-lock"),
            id: "/msp/phish_titan/configuration/link_lock/",
            dataTestId: "msp-phish_titan-configuration-link-lock",
            disabled: false,
          },
        ],
      },
      {
        label: i18n.global.t("Notifications"),
        id: "/msp/phish_titan/notifications",
        dataTestId: "msp-phish_titan-notifications",
      },
      {
        label: i18n.global.t("Reports"),
        id: "/msp/phish_titan/reports",
        dataTestId: "msp-phish_titan-reports",
        hasEvent: true,
        children: [
          {
            label: i18n.global.t("Summary"),
            id: "/msp/phish_titan/reports/summary",
            dataTestId: "msp-phish_titan-reports-summary",
          },
          {
            label: i18n.global.t("Insights"),
            id: "/msp/phish_titan/reports/insights",
            dataTestId: "msp-phish_titan-reports-insights",
          },
        ],
      },
    ],
  },
];
export const CUSTOMER_NAV_ITEMS = [
  {
    order: 1,
    label: i18n.global.t("PhishTitan"),
    groupBy: SIDENAV_GROUP_BY.BETA,
    icon: "cl-icon-tabler-mail",
    id: "/customer/phish_titan",
    dataTestId: "customer-phish-titan",
    solutionId: PHISH_SERVICE,
    children: [
      {
        label: i18n.global.t("Overview"),
        id: "/customer/phish_titan/overview",
        dataTestId: "customer-phish_titan-overview",
      },
      {
        label: i18n.global.t("Incidents"),
        id: "/customer/phish_titan/incidents",
        dataTestId: "customer-phish_titan-incidents",
        permanentPill: { variant: "infoFull", text: i18n.global.t("AI") },
      },
      {
        label: i18n.global.t("Configuration"),
        status: NEW,
        id: "/customer/phish_titan/configuration",
        disabled: false,
        dataTestId: "customer-phish_titan-configuration",
        hasEvent: true,
        children: [
          {
            status: NEW,
            label: i18n.global.t("sidebar-mail-flow"),
            id: "/customer/phish_titan/configuration/mail_flow",
            dataTestId: "customer-phish_titan-configuration-mail-flow",
          },
          {
            label: i18n.global.t("page-title-link-lock"),
            id: "/customer/phish_titan/configuration/link_lock/",
            dataTestId: "customer-phish_titan-link-lock",
          },
        ],
      },
      {
        label: i18n.global.t("Notifications"),
        id: "/customer/phish_titan/notifications",
        dataTestId: "customer-phish_titan-notifications",
      },
      {
        label: i18n.global.t("Reports"),
        id: "/customer/phish_titan/reports",
        dataTestId: "customer-phish_titan-reports",
        hasEvent: true,
        children: [
          {
            label: i18n.global.t("Summary"),
            id: "/customer/phish_titan/reports/summary",
            dataTestId: "customer-phish_titan-reports-summary",
          },
          {
            label: i18n.global.t("Insights"),
            id: "/customer/phish_titan/reports/insights",
            dataTestId: "customer-phish_titan-reports-insights",
          },
        ],
      },
    ],
  },
];

export default {};
