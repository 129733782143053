import { Params } from "@/types/api";

export type SenderType = "email" | "domain";

type DomainToAllow = {
  organisationID: string;
  type: "domain";
  domain: string;
  comment?: string;
};

type EmailToAllow = {
  organisationID: string;
  type: SenderType;
  email: string;
  comment?: string;
};
type DomainToEdit = DomainToAllow & { id: string };
type EmailToEdit = EmailToAllow & { id: string };

export type SenderToAllow = DomainToAllow | EmailToAllow;
export type SenderToEdit = DomainToEdit | EmailToEdit;

export type SendersToGet = {
  organisationID: string;
  type: SenderType;
  params: Params;
};

export interface SendersToDelete {
  organisationID: string;
  type: SenderType;
  ids: string[];
}

export type Sender = {
  action: "allow" | "block";
  id: string;
  type: SenderType;
  updated_at: string;
  value: string;
  comment: string;
};

export const isEmailToAllow = (
  sender: EmailToAllow | DomainToAllow,
): sender is EmailToAllow => {
  return "email" in sender;
};

export const isEmailToEdit = (
  sender: EmailToEdit | DomainToEdit,
): sender is EmailToEdit => {
  return "email" in sender;
};

export type FreeDomain = {
  type: "domain";
  value: string;
};
