import alert from "@/store/modules/alert";
import authentication from "@/store/modules/authentication";
import azure from "@/store/modules/azure";
import arctitan from "@/store/modules/arctitan";
import history from "@/store/modules/history";
import locations from "@/store/modules/locations";
import modal from "@/store/modules/modal";
import microsoftConnect from "@/store/modules/microsoftConnect";
import pageMeta from "@/store/modules/pageMeta";
import toast from "@/store/modules/toast";
import userPrefs from "@/store/modules/userPrefs";
import users from "@/store/modules/users";
import passwordReset from "./passwordReset";
import powerBi from "@/store/modules/powerbi";
import organisations from "@/store/modules/organisations";
import messagesAnalysed from "./messagesAnalysed";
import allowBlock from "./allowBlock";
import remediation from "@/modules/PhishTitan/store/remediation";
import arcTitan from "@/modules/ArcTitan/store/arcTitan";
import exploitedDomains from "@/modules/PhishTitan/store/exploitedDomains";
import solutions from "./solutions";
import dropDown from "./dropDown";
import antiSpoofing from "@/modules/PhishTitan/store/antiSpoofing";
import graymail from "@/modules/PhishTitan/store/graymail";

export default {
  alert,
  authentication,
  azure,
  arctitan,
  history,
  locations,
  modal,
  microsoftConnect,
  pageMeta,
  toast,
  userPrefs,
  users,
  organisations,
  passwordReset,
  powerBi,
  messagesAnalysed,
  allowBlock,
  remediation,
  arcTitan,
  exploitedDomains,
  solutions,
  dropDown,
  antiSpoofing,
  graymail,
};
