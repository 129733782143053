import { $http } from "@/plugins/api";
import type { Commit } from "vuex";
import {
  Apiservice,
  Organisation,
  Uuid,
  OrganisationsTotal,
} from "../../types/organisatio";
import { Params } from "@/types/api";
interface State {
  organisations: Organisation[];
  organisationsTotal: OrganisationsTotal;
  orgsPerCustomer: Organisation[];
}

const state: State = {
  organisations: [],
  organisationsTotal: { total: 0 },
  orgsPerCustomer: [],
};

const getters = {
  orginisationsList: (state: { organisations: State }) => state.organisations,
  organisationsTotal: (state: { organisationsTotal: State }) =>
    state.organisationsTotal,
  orgsPerCustomer: (state: { orgsPerCustomer: State }) => state.orgsPerCustomer,
};

const actions = {
  clearOrgCache({ commit }: { commit: Commit }) {
    commit("resetState", []);
  },

  async updateOrg(
    { commit }: { commit: Commit },
    { formData, parent_uuid, organisation_type }: Apiservice,
  ) {
    const postData = {
      ...(formData.name && { name: formData.name }),
      ...(formData.country && { country: formData.country }),
      ...(parent_uuid && { parent_uuid: parent_uuid }),
      ...(parent_uuid && { billing_organisation_uuid: parent_uuid }),
      ...(formData.description && { description: formData.description }),
      ...(formData.customerID && {
        billing_system_id: formData.customerID,
      }),
      ...(organisation_type && {
        organisation_type: organisation_type,
      }),
    };
    const res = await $http.patch(
      `${import.meta.env.VITE_API_CLIENT}/idp/organisations/${formData.uuid}`,
      postData,
    );
    commit("updateOrg", res.data);
    return res.data;
  },

  async showOrg({ commit }: { commit: Commit }, { uuid }: Uuid) {
    const res = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/organisations/${uuid}`,
    );
    commit("updateOrg", res.data);
    return res;
  },

  async getCustomerPerUsageOrg(
    { commit }: { commit: Commit },
    {
      parent_uuid,
      limit,
      filter,
      sortdir,
      offset = 0,
      sortkey = null,
    }: unknown,
  ) {
    let url = `${import.meta.env.VITE_API_CLIENT}/idp/organisations`;

    if (parent_uuid) url += `?parent_uuid=${parent_uuid}`;
    if (limit) url += `&limit=${limit}`;
    if (filter) url += `&filter=${filter}`;
    if (sortkey) url += `&sortkey=${sortkey}`;
    if (offset) url += `&offset=${offset}`;
    if (sortdir) url += `&sortdir=${sortdir}`;

    const orgsResponse = await $http.get(url);

    commit("orgsPerCustomerList", orgsResponse.data.data);
  },

  async getOrginisationsList(
    { commit }: { commit: Commit },
    {
      parent_uuid,
      params,
    }: {
      parent_uuid: string | null;
      params: Params;
    },
  ) {
    const baseUrl = `${import.meta.env.VITE_API_CLIENT}/idp/organisations`;
    const url = new URL(baseUrl);
    const reqParams = new URLSearchParams();
    if (parent_uuid) {
      reqParams.append("parent_uuid", parent_uuid);
    }

    // Convert params to lowercase strings and append them to reqParams
    for (const [key, value] of Object.entries(params)) {
      reqParams.append(key, value.toString());
    }

    url.search = reqParams.toString();

    const orgsResponse = await $http.get(url.toString());

    commit("orgsList", orgsResponse.data.data);
    commit("orgsTotal", orgsResponse.data.total);
    return orgsResponse.data;
  },

  async batchDeleteOrgs(
    { commit }: { commit: Commit },
    { accounts }: { accounts: { uuid: string }[] },
  ) {
    const req: { [key: string]: { method: string; path: string } } = {};
    for (const account of accounts) {
      req[account.uuid] = {
        method: "DELETE",
        path: `/api/organisations/${account.uuid}`,
      };
    }

    const res = await $http.post("/batch", req);
    const d = Object.values(res.data);
    const canDelete = d.filter(
      (value: unknown) => (value as { code: number }).code === 200,
    );

    if (canDelete) {
      interface YourObjectType {
        response: {
          uuid: string;
        };
      }
      const ids = canDelete.map((item: unknown) => {
        const typedItem = item as YourObjectType;
        return typedItem.response.uuid;
      });
      commit("deleteOrgs", ids);
    }
    return d;
  },

  async createPhishTitanOrg(_context: unknown, organisation_uuid: unknown) {
    await $http.post(`${import.meta.env.VITE_API_CLIENT}/pt/orgs`, {
      organisationID: organisation_uuid,
      globalOrganisationID: organisation_uuid,
    });
  },

  async createOrginisation(
    { commit }: { commit: Commit },
    { formData, parent_uuid, organisation_type }: Apiservice,
  ) {
    const url = parent_uuid
      ? `${import.meta.env.VITE_API_CLIENT}/idp/organisations?parent_uuid=${parent_uuid}/organisations`
      : `${import.meta.env.VITE_API_CLIENT}/idp/organisations`;

    const postData: { [key: string]: unknown } = {
      ...(formData.name && { name: formData.name }),
      ...(formData.country && { country: formData.country }),
      ...(formData.description && { description: formData.description }),
      ...(formData.customerID && {
        billing_system_id: formData.customerID,
      }),
      ...(organisation_type && {
        organisation_type: organisation_type,
      }),
    };
    postData.parent_uuid = parent_uuid;
    const res = await $http.post(url, postData);
    commit("updateOrgsList", res.data);
    return res;
  },
};

const mutations = {
  resetState(state: State, data: Organisation[]): void {
    state.organisations = [...data];
  },
  deleteOrgs(state: { organisations: Organisation[] }, ids: string[]) {
    for (const id of ids) {
      state.organisations = state.organisations.filter(
        (organisation) => organisation.uuid !== id,
      );
    }
  },
  orgsPerCustomerList: (
    state: { orgsPerCustomer: Organisation[] },
    data: Organisation[],
  ) => (state.orgsPerCustomer = data),
  orgsList: (state: { organisations: Organisation }, orgs: Organisation) =>
    (state.organisations = orgs),
  orgsTotal: (
    state: { organisationsTotal: OrganisationsTotal },
    total: number,
  ) => (state.organisationsTotal = { total: total }),
  updateOrgsList(state: { organisations: Organisation[] }, org: Organisation) {
    state.organisations.push(org);
  },
  updateOrg(
    state: { organisations: Organisation[] },
    updatedOrg: Organisation,
  ): void {
    state.organisations = state.organisations.map((org) =>
      org.uuid === updatedOrg.uuid ? updatedOrg : org,
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
