/* c8 ignore start */
import { mspRoutes } from "./routes";

import modules from "./store";

import { useFlag } from "@/composables/useFlags/useFlag";
import { NAV_ITEMS_MODE } from "@/constants";

import { MSP_NAV_ITEMS } from "./constants";

const { isFeatureAvailable: isMspSatAvailable } = useFlag("SAT_MSP");

const SecurityAwarnessTrainingModule = ({
  router,
  store,
  registerNavItems,
}) => {
  // Routes

  if (isMspSatAvailable.value) {
    router.addRoute(mspRoutes);
  }

  // Stores
  store.registerModule("sat_customers", modules.customers);
  store.registerModule("sat_campaigns", modules.campaigns);
  store.registerModule("sat_courses", modules.courses);

  // Nav Items
  if (isMspSatAvailable.value) {
    registerNavItems(NAV_ITEMS_MODE.MSP, MSP_NAV_ITEMS);
  }
};

export { SecurityAwarnessTrainingModule };

export default {};
/* c8 ignore end */
