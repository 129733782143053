import { $http } from "@/plugins/api";
import { Commit } from "vuex";

interface GraymailSettings {
  status: {
    value: boolean;
    inherited_from: string | null;
  };
}

interface GraymailState {
  settings?: GraymailSettings;
}

const state: GraymailState = {
  settings: undefined,
};

const getters = {
  settingsStatus: (state: GraymailState) => state.settings?.status.value,
};

const actions = {
  async getGraymailSettings(
    { commit }: { commit: Commit },
    {
      organisationID,
    }: {
      organisationID: string;
    },
  ) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/settings/${organisationID}/graymail`,
    );

    commit("setGraymailSettings", res.data);
  },
  async putGraymailSettings(
    { commit }: { commit: Commit },
    {
      organisationID,
      status,
    }: {
      organisationID: string;
      status: boolean;
    },
  ) {
    const res = await $http.put(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/settings/${organisationID}/graymail`,
      {
        status,
      },
      { _hasCustomErrorHandling: true },
    );

    commit("setGraymailSettings", res.data);
  },
};

const mutations = {
  setGraymailSettings: (state: GraymailState, data: GraymailSettings) =>
    (state.settings = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
