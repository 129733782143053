import { Incident } from "./../types/Incidents";
import { $http } from "@/plugins/api";
import { Collection, Params } from "@/types/api";
import { Commit } from "vuex";
import { useFlag } from "@/composables/useFlags/useFlag";
// @TODO: move type to PhishTitan/types after INCIDENTS_V3 is available on prod
import { IncidentDetails } from "../types/IncidentSummary";

const { isFeatureAvailable: isIncidentsV3Available } = useFlag("INCIDENTS_V3");
interface IncidentsState {
  incidentsList: Collection<Incident>;
  selectedIncident: Incident | null;
  incident: IncidentDetails | null;
}

const defaultIncidentsList = {
  current_page: 1,
  page_size: 10,
  results: [],
  total: 0,
};

const state: IncidentsState = {
  incidentsList: defaultIncidentsList,
  selectedIncident: null,
  incident: null,
};

const getters = {
  collection: (state: IncidentsState) => state.incidentsList,
  selectedIncident: (state: IncidentsState) => state.selectedIncident,
  incident: (state: IncidentsState) => state.incident,
};

const actions = {
  async getIncidentsList(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      params,
    }: {
      globalOrganisationID: string;
      params: Params;
    },
  ) {
    commit("resetIncidentsList");

    if (isIncidentsV3Available.value) {
      const paramsWithoutEmptyValues = Object.fromEntries(
        Object.entries(params).filter((param) => param[1]),
      );

      const searchParams = new URLSearchParams(paramsWithoutEmptyValues);

      const res = await $http.get(
        `${import.meta.env.VITE_API_CLIENT_V3}/history/${globalOrganisationID}/incidents?${searchParams.toString()}`,
      );

      commit("setIncidentsList", res.data);
    } else {
      const searchParams = new URLSearchParams(params);

      const res = await $http.get(
        `${import.meta.env.VITE_API_CLIENT_V2}/history/${globalOrganisationID}/incidents?${searchParams.toString()}`,
      );

      commit("setIncidentsList", res.data);
    }
  },
  async getIncident(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      ingressID,
      params,
    }: { globalOrganisationID: string; ingressID: string; params: Params },
  ) {
    commit("setIncident", null);

    const searchParams = new URLSearchParams(params);

    const res = await $http.get(
      `${import.meta.env.VITE_API_CLIENT_V3}/history/${globalOrganisationID}/incidents/${ingressID}/details?${searchParams.toString()}`,
    );

    commit("setIncident", res.data);
  },
};

const mutations = {
  setIncidentsList: (state: IncidentsState, data: Collection<Incident>) =>
    (state.incidentsList = data),
  setSelectedIncident: (state: IncidentsState, data: Incident) =>
    (state.selectedIncident = data),
  setIncident: (state: IncidentsState, data: IncidentDetails) =>
    (state.incident = data),
  resetIncidentsList: (state: IncidentsState) =>
    (state.incidentsList = defaultIncidentsList),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
