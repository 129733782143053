import { $http } from "@/plugins/api";

const state = {
  all: { data: [] },
  selectedLocation: null,
};

const getters = {
  locations: (state) => state,
  selectedLocation: (state) => state.selectedLocation,
};

const actions = {
  async fetchLocations({ commit }, { id, type }) {
    const response = await $http.get(`/restapi/users/${id}/locations/${type}`);

    response.data.data = response.data.data.map((location) => {
      if (location.type === "staticip") {
        return { ...location, ip: location.addresses[0].ip };
      }
      return location;
    });
    commit("setLocations", {
      type,
      data: response.data,
    });
  },

  async fetchLocation({ commit }, { id, type, locationId }) {
    const response = await $http.get(
      `/restapi/users/${id}/locations/${type}/${locationId}`,
    );

    commit("setSelectedLocation", response.data);
  },

  async createLocation({ commit }, { id, type, body }) {
    const response = await $http.post(
      `/restapi/users/${id}/locations/${type}`,
      body,
    );
    if (response.data.type === "staticip") {
      response.data = { ...response.data, ip: response.data.addresses[0].ip };
    }
    commit("addLocation", response.data);

    return response.data;
  },

  async updateLocation({ commit }, { id, type, locationId, body }) {
    const response = await $http.post(
      `/restapi/users/${id}/locations/${type}/${locationId}`,
      body,
    );

    if (response.data.type === "staticip") {
      response.data = { ...response.data, ip: response.data.addresses[0].ip };
    }

    const index = state.all.data.findIndex(
      (location) => location.id === response.data.id,
    );

    commit("updateLocation", { data: response.data, idx: index });
    commit("setSelectedLocation", response.data);
  },

  async deleteLocations({ commit }, { userId, locations }) {
    const req = {};

    for (const location of locations) {
      req[`${location.id}`] = {
        method: "delete",
        path: `users/${userId}/locations/${location.type}/${location.id}`,
      };
    }

    const res = await $http.post("/restapi/batch", req);
    const ids = Object.keys(res.data).map((item) => +item);
    commit("deleteLocation", ids);
  },
};

const mutations = {
  setLocations: (state, data) => (state.all = data.data),
  setSelectedLocation: (state, data) => (state.selectedLocation = data),
  addLocation: (state, data) => state.all.data.push(data),
  updateLocation: (state, data) => (state.all.data[`${data.idx}`] = data.data),
  deleteLocation(state, locations) {
    for (const location of locations) {
      state.all.data = state.all.data.filter(
        (stateLocation) => stateLocation.id !== location,
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
