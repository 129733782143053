import { $http } from "@/plugins/api";

const state = {
  email: "",
};

const getters = {
  email: (state) => state.email,
};

const actions = {
  async requestResetLink({ commit }, { email, domain }) {
    commit("setEmail", email);

    return await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/idp/password/forgot`,
      {
        domain,
        email,
      },
    );
  },
  async setNewPassword(
    { commit },
    { email, password, confirm_password, token },
  ) {
    commit("setEmail", email);
    return await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/idp/password/reset`,
      {
        email,
        password,
        confirm_password,
        token,
      },
    );
  },
};

const mutations = {
  setEmail: (state, data) => (state.email = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
