export const mspRoutes = {
  path: "/msp",
  component: () => import("@/layouts/Msp/MspLayout.vue"),
  children: [
    {
      path: "settings",
      name: "mspSettings",
      component: () => import("@/modules/Settings/views/Msp"),
      redirect: {
        name: "msp-settings-administrators",
      },
      children: [
        {
          path: "administrators",
          name: "msp-settings-administrators",
          component: () =>
            import("@/modules/Settings/views/Msp/Administrators/index.vue"),
        },
        {
          path: "administrators/:name",
          name: "msp-settings-administrators-edit",
          component: () =>
            import("@/modules/Settings/views/Common/Administrators/Edit"),
        },
        {
          path: "saml-sso",
          name: "msp-settings-saml-sso",
          component: () =>
            import("@/modules/Settings/views/Msp/Saml/index.vue"),
        },
      ],
    },
  ],
};

export default {};
