import { computed } from "vue";
import { useCookies } from "@vueuse/integrations/useCookies";

import { COOKIE_SETTINGS } from "@/utils/cookies";
import { SideNavItemStatus, COMING_SOON, SideNavPill } from "@/types/side-nav";
import { useFlag } from "@/composables/useFlags/useFlag";

const cookies = useCookies();
const NEW_FEATURES = "new-features";

const { isFeatureAvailable: isGraymailAvailable } = useFlag("GRAYMAIL");

export const modalSeenCookieName = isGraymailAvailable.value
  ? "v8_graymailModalSeen"
  : "v7_incidentSlideOutModalSeen";
export const pageVisitedCookieName = isGraymailAvailable.value
  ? "v8_graymailPageVisited"
  : "v7_incidentSlideOutPageVisited";
type Feature = typeof modalSeenCookieName | typeof pageVisitedCookieName;

export const newFeatureModalSeenCookie = computed(
  () => cookies.get(NEW_FEATURES)?.[modalSeenCookieName],
);

export const newFeaturePageVisitedCookie = computed(
  () => cookies.get(NEW_FEATURES)?.[pageVisitedCookieName],
);

export const setCookie = (feature: Feature) =>
  cookies.set(
    NEW_FEATURES,
    {
      ...cookies.get(NEW_FEATURES),
      [feature]: true,
    },
    COOKIE_SETTINGS,
  );

export const getSideNavItemPill = (status: SideNavItemStatus): SideNavPill => {
  if (status === COMING_SOON) {
    return { variant: "info", text: "Coming soon" };
  }
  return { variant: "success", text: "New" };
};
