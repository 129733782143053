import { createRouter } from "vue-router";
import AuthLayout from "@/layouts/Auth/AuthLayout.vue";
import MspLayout from "@/layouts/Msp/MspLayout.vue";
import CustomerLayout from "@/layouts/Customer/CustomerLayout.vue";
import SystemLayout from "@/layouts/System/SystemLayout.vue";
import mspRoutes from "./routes/mspRoutes";
import authRoutes from "./routes/authRoutes";
import systemRoutes from "./routes/systemRoutes";
import NotFoundPage from "../views/NotFoundPage/NotFoundPage";
import ProfileLayout from "@/layouts/Profile/ProfileLayout.vue";
import profileRoutes from "./routes/profileRoutes";
import { createWebHistory } from "vue-router";
import customerRoutes from "./routes/customerRoutes";

const routes = [
  {
    path: "/",
    component: NotFoundPage,
  },
  {
    path: "/password/forgot",
    component: NotFoundPage,
    redirect: {
      name: "auth-password-set",
    },
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: authRoutes,
  },

  {
    path: "/sso/callback",
    name: "ssoCalback",
    component: () => import("@/views/Auth/Sso"),
  },
  {
    path: "/azure/callback",
    name: "azureCalback",
    component: () => import("@/views/Auth/Callback"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/Auth/Logout"),
  },
  {
    path: "/msp",
    component: MspLayout,
    name: "msp-root",
    children: mspRoutes,
  },
  {
    path: "/customer",
    component: CustomerLayout,
    children: customerRoutes,
  },
  {
    path: "/system",
    component: SystemLayout,
    children: systemRoutes,
  },
  {
    path: "/profile",
    component: ProfileLayout,
    children: profileRoutes,
  },
  {
    path: "/:catchAll(.*)",
    redirect: {
      name: "auth-sign-in",
    },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default {};
