import { i18n } from "@/i18n";
import { SIDENAV_GROUP_BY } from "@/constants";

export const MSP_NAV_ITEMS = [
  {
    order: 7,
    groupBy: SIDENAV_GROUP_BY.DELTA,
    label: i18n.global.t("Settings"),
    icon: "cl-icon-tabler-settings",
    id: "/msp/settings",
    dataTestId: "msp-settings",
    children: [
      {
        label: i18n.global.t("Administrators"),
        id: "/msp/settings/administrators",
        dataTestId: "msp-settings-administrators",
      },
      {
        label: i18n.global.t("SAML SSO"),
        id: "/msp/settings/saml-sso",
        dataTestId: "msp-settings-saml-sso",
      },
    ],
  },
];
export const CUSTOMER_NAV_ITEMS = [
  {
    order: 7,
    groupBy: SIDENAV_GROUP_BY.DELTA,
    label: i18n.global.t("Settings"),
    icon: "cl-icon-tabler-settings",
    id: "/customer/settings",
    dataTestId: "customer-settings",
    children: [
      {
        label: i18n.global.t("Administrators"),
        id: "/customer/settings/administrators",
        dataTestId: "customer-settings-administrators",
      },

      {
        label: i18n.global.t("Connections"),
        id: "/customer/settings/connections",
        dataTestId: "customer-settings-connections",
      },
      {
        label: i18n.global.t("SAML SSO"),
        id: "/customer/settings/saml-sso",
        dataTestId: "customer-settings-saml-sso",
      },
    ],
  },
];

export default {};
