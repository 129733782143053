const getDefaultMicrosoftState = () => {
  return {
    isConnectMicrosoft: null,
  };
};

const state = getDefaultMicrosoftState();

const getters = {
  isConnectedMicrosoft: (state) => state.isConnectMicrosoft,
};

const actions = {
  clearConnections({ commit }) {
    commit("resetState");
  },

  async connectToMicroSoft({ commit }, val) {
    commit("microsoftConnection", val);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultMicrosoftState());
  },
  microsoftConnection: (state, val) => (state.isConnectMicrosoft = val),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
