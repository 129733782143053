import { $http } from "@/plugins/api";

import type { Commit } from "vuex";
import type { Collection, Params } from "@/types/api";
import type { SATCustomer } from "./types";

interface State {
  customers: Collection<SATCustomer>;
}

const state: State = {
  customers: {
    current_page: 1,
    page_size: 5,
    results: [],
    total: 0,
  },
};

export const getters = {
  customers: (state: State) => state.customers,
};
export const actions = {
  async getCustomers(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      params,
    }: {
      globalOrganisationID: string | null;
      params: Params;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const reqParams: Params = {};

    for (const [key, value] of Object.entries(params)) {
      reqParams[key] = value.toString().toLocaleLowerCase();
    }

    const searchParams = new URLSearchParams(reqParams);
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/sat/${globalOrganisationID}/customers?${searchParams.toString()}`,
    );

    commit("setCustomers", res.data);
  },
};
export const mutations = {
  setCustomers: (state: State, data: Collection<SATCustomer>) =>
    (state.customers = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
