import { $http } from "@/plugins/api";

import type { Commit } from "vuex";
import type { Solution } from "@/store/types";

interface State {
  solutions: Solution[];
}

const state: State = {
  solutions: [],
};

const getters = {
  solutions: (state: State) => state.solutions,
};

const actions = {
  resetState({ commit }: { commit: Commit }) {
    commit("setSolutions", []);
  },
  async getSolutions(
    { commit }: { commit: Commit },
    { uuid }: { uuid: string },
  ) {
    if (!uuid) {
      throw new Error("Organisation ID is not provided");
    }

    const solutions = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/licence/organisations/${uuid}/licences`,
    );

    commit("setSolutions", solutions.data.data);
  },
};

const mutations = {
  setSolutions: (state: State, data: Solution[]) => (state.solutions = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
