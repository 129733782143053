import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = import.meta.glob("./locales/*.json", {
    query: "?raw",
    import: "default",
    eager: true,
  });

  const dittoLocales = import.meta.glob("./locales/ditto/*.json", {
    query: "?raw",
    import: "default",
    eager: true,
  });

  const messages = {};

  Object.keys(locales).forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = JSON.parse(locales[key]);
    }
  });

  Object.keys(dittoLocales).forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = JSON.parse(dittoLocales[key]);
    }
  });

  return messages;
}

export const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
  legacy: false,
  allowComposition: true,
});

export default {};
