import { $http } from "@/plugins/api";

const getDefaultLicence = () => {
  // Stub
};

const getDefaultPowerBI = () => {
  return {
    report: null,
    config: null,
  };
};
const state = getDefaultPowerBI();

const getters = {
  getReportData: (state) => state.report,
  getPowerBiReportID: (state) => state.config,
};

const actions = {
  clearPowerBICache({ commit }) {
    commit("resetState");
  },
  async getPowerBiConfig({ commit }, uuid) {
    const response = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/powerbi/config?organisation_uuid=${uuid}`,
    );

    commit("setPowerBiConfig", response.data);
  },

  async fetchPowerBiApi({ commit }, { rid, uuid }) {
    const response = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/idp/powerbi/authenticate`,
      {
        report_uuid: rid,
        organisation_uuid: uuid,
      },
    );

    commit("setReportData", response.data);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultLicence());
  },
  setReportData: (state, data) => (state.report = data),
  setPowerBiConfig(state, data) {
    state.config = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
