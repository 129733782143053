import { mspRoutes, customerRoutes } from "./routes";

import TAddAllowedUrl from "./modals/TAddAllowedUrl.vue";
import TAddAdministrator from "./modals/TAddAdministrator.vue";

import modules from "./store";

import { NAV_ITEMS_MODE } from "@/constants";
import { MSP_NAV_ITEMS, CUSTOMER_NAV_ITEMS } from "./constants";

const SettingsModule = ({ app, router, store, registerNavItems }) => {
  // Components
  app.component("TAddAllowedUrl", TAddAllowedUrl);
  app.component("TAddAdministrator", TAddAdministrator);

  // Routes
  router.addRoute(mspRoutes);
  router.addRoute(customerRoutes);
  // Stores
  store.registerModule("sso", modules.sso);

  // Nav Items
  registerNavItems(NAV_ITEMS_MODE.MSP, MSP_NAV_ITEMS);
  registerNavItems(NAV_ITEMS_MODE.CUSTOMER_ADMIN, CUSTOMER_NAV_ITEMS);
};

export { SettingsModule };

export default {};
