/* c8 ignore start */
import customers from "./customers";
import campaigns from "./campaigns";
import courses from "./courses";

export default {
  campaigns,
  customers,
  courses,
};
/* c8 ignore end */
