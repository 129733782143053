import { ARC_SERVICE } from "@/utils/services";

export const customerRoutes = {
  path: "/customer",
  component: () => import("@/layouts/Customer/CustomerLayout.vue"),
  children: [
    {
      path: "arc_titan",
      name: "customer-arc_titan",
      component: () => import("@/modules/ArcTitan/Views/Common"),
      meta: {
        solutionId: ARC_SERVICE,
      },
    },
  ],
};

export default {};
