import { $http } from "@/plugins/api";

const mailHistory = () => {
  return {
    mailFlowing: null,
    totalEmailsAnalyzed: 0,
    totalIncidents: 0,
  };
};
const state = mailHistory();

const getters = {
  getMailFlow: (state) => state.mailFlowing,
  totalEmailsAnalyzed: (state) => state.totalEmailsAnalyzed,
  totalIncidents: (state) => state.totalIncidents,
};

const actions = {
  clearMailHistoryCache({ commit }) {
    commit("resetState");
  },

  async getMailHistory({ commit }, organisation) {
    const mailRes = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/history/platform/PhishTitan/type/General/globalOrganisationID/${organisation}/count`,
    );

    commit("setMailFlowing", mailRes.data);
  },
  async getTotalEmailsAnalyzed({ commit }, { organisationId, params }) {
    const filterParams = new URLSearchParams(params);

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/history/platform/PhishTitan/type/General/globalOrganisationID/${organisationId}/count?${filterParams.toString()}`,
    );

    commit("setTotalEmailsAnalyzed", res.data.Count);
  },
  async getTotalIncidents({ commit }, { organisationId, params }) {
    const filterParams = new URLSearchParams(params);

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/history/platform/PhishTitan/type/Classified/globalOrganisationID/${organisationId}/count?${filterParams.toString()}`,
    );
    commit("setTotalIncidents", res.data.Count);
  },
};

const mutations = {
  setTotalEmailsAnalyzed: (state, count) => (state.totalEmailsAnalyzed = count),
  setMailFlowing: (state, mailFlow) => (state.mailFlowing = mailFlow),
  setTotalIncidents: (state, count) => (state.totalIncidents = count),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
