import { reactive } from "vue";
import { createGlobalState } from "@vueuse/core";
import { i18n } from "@/i18n";
import { SIDENAV_GROUP_BY } from "@/constants";
import { NAV_ITEMS_MODE } from "@/constants";
const SYSTEM_LINKS = [
  {
    order: 1,
    label: i18n.global.t("Dashboard"),
    icon: "cl-icon-tabler-dashboard",
    id: "/system/dashboard",
    dataTestId: "system-dashboard",
    groupBy: SIDENAV_GROUP_BY.ALPHA,
  },
  {
    order: 2,
    label: i18n.global.t("Settings"),
    icon: "cl-icon-tabler-settings",
    id: "/system/settings",
    dataTestId: "system-settings",
    groupBy: SIDENAV_GROUP_BY.BETA,
    children: [
      {
        label: i18n.global.t("SAML SSO"),
        id: "/system/settings/saml-sso",
        dataTestId: "system-settings-saml-sso",
      },
    ],
  },
];

const MSP_LINKS = [
  {
    order: 0,
    label: i18n.global.t("Customers"),
    icon: "cl-tabler-icon-users",
    exact: true,
    groupBy: SIDENAV_GROUP_BY.ALPHA,
    id: "/msp/customers",
    dataTestId: "msp-customers",
  },
];

const CUSTOMER_LINKS = [
  {
    order: 0,
    label: i18n.global.t("Customers"),
    icon: "cl-icon-tabler-overview",
    exact: true,
    groupBy: SIDENAV_GROUP_BY.ALPHA,
    id: "/customer/phish_titan/overview",
    dataTestId: "customer-overview",
  },
];

const PROFILE_LINKS = [
  {
    order: 1,
    label: i18n.global.t("Security"),
    icon: "info-circle",
    exact: false,
    id: "/profile/security",
    dataTestId: "profile-security",
  },
];

const sortByOrder = (a, b) => a.order - b.order;

export const usePrimaryNavItems = createGlobalState(() => {
  const navItemsState = new Map();

  navItemsState.set(NAV_ITEMS_MODE.SYSTEM, [...SYSTEM_LINKS].sort(sortByOrder));
  navItemsState.set(NAV_ITEMS_MODE.MSP, [...MSP_LINKS].sort(sortByOrder));
  navItemsState.set(
    NAV_ITEMS_MODE.CUSTOMER_ADMIN,
    [...CUSTOMER_LINKS].sort(sortByOrder),
  );
  navItemsState.set(
    NAV_ITEMS_MODE.PROFILE,
    [...PROFILE_LINKS].sort(sortByOrder),
  );

  const navItems = reactive(navItemsState);
  const registerNavItems = (type, items) => {
    if (!navItems.has(type)) {
      throw new Error(`No links for type: '${type}'`);
    }

    const prevLinks = navItems.get(type);
    navItems.set(type, [...prevLinks, ...items].sort(sortByOrder));
  };

  return { navItems, registerNavItems };
});

export default {};
