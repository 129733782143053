import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const $userPrefs = {
  init() {
    let userPrefs = getLocalUserPrefs();

    if (!userPrefs) {
      userPrefs = {
        lang: navigator.language,
        timezone: dayjs.tz.guess(),
        ui: {
          logo: null,
          title: "PhishTitan",
        },
      };

      if (userPrefs && userPrefs.ui === undefined) {
        userPrefs.ui = {
          logo: null,
          title: null,
        };
      }

      setLocalUserPrefs(userPrefs);
    }

    return userPrefs;
  },
  /**
   * Updates user preferences in local storage.
   * @param {object} newUserPrefs Key/val pair of user preferences
   * @returns user preferences object
   */
  updateUserPrefs(newUserPrefs) {
    let userPrefs = getLocalUserPrefs();

    if (!userPrefs && import.meta.env.NODE_ENV === "development") {
      console.warn("did you forget to run `$userPrefs.init()`"); // eslint-disable-line no-console
      return;
    }

    userPrefs = {
      ...userPrefs,
      ...newUserPrefs,
    };

    setLocalUserPrefs(userPrefs);
    return userPrefs;
  },
  getCurrentToken() {
    const userPrefs = getLocalUserPrefs();
    return userPrefs ? userPrefs.token : null;
  },
  getUserRole() {
    const userPrefs = getLocalUserPrefs();
    return userPrefs ? userPrefs.role : null;
  },
  getSelectedMSP() {
    return getLocalSelectedMsp() || null;
  },
  get2FAEnabled() {
    const userPrefs = getLocalUserPrefs();
    return userPrefs.twoFactorAuthEnabled;
  },
  /**
   * Returns the formatted date and time string with the users selected timezone.
   * If needed, the timezone can be overridden by passing in the third param,
   * also the date and time format can be changed by passing in the second param.
   * @param {string} timeAndDate
   * @param {string} dateTimeFormat
   * @param {null|string} overrideTz
   * @returns {string}
   */
  displayDateTime(
    timeAndDate,
    dateTimeFormat = "YYYY-MM-DD HH:mm:ss",
    overrideTz = null,
  ) {
    return formatDateAndTimeToUserTZ(timeAndDate, overrideTz).format(
      dateTimeFormat,
    );
  },

  getCurrentUi() {
    const userPrefs = getLocalUserPrefs();
    if (userPrefs) {
      return userPrefs.ui;
    }
  },
};

export default {
  install(app) {
    app.config.globalProperties.$windowResult = "";
    app.config.globalProperties.$userPrefs = $userPrefs;
  },
};

/**
 * @param {string} timeAndDate
 * @param {string} overrideTz
 * @returns {dayjs.Dayjs}
 */
function formatDateAndTimeToUserTZ(timeAndDate, overrideTz) {
  let tz = overrideTz || getLocalUserPrefs().timezone;
  return dayjs(timeAndDate).tz(tz);
}

function getLocalUserPrefs() {
  return JSON.parse(localStorage.getItem("user_prefs"));
}

function getLocalSelectedMsp() {
  return JSON.parse(localStorage.getItem("selectedMsp"));
  // return 2;
}

function setLocalUserPrefs(userPrefs) {
  localStorage.setItem("user_prefs", JSON.stringify(userPrefs));
}
