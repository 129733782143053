<template>
  <div class="tw-relative tw-w-full">
    <router-view />
    <vue3-progress-bar />
    <t-toasts />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import TToasts from "@/components/TToasts";

import { useTheme } from "@/composables";

export default {
  components: {
    TToasts,
  },
  setup() {
    useTheme();
  },
  computed: {
    ...mapGetters("authentication", [
      "selectedAccount",
      "authUser",
      "callbackID",
    ]),
    isLoggedIn() {
      return this.selectedAccount !== null;
    },
    name() {
      return this.authUser !== null ? this.authUser.accountname : "";
    },
    email() {
      return this.authUser !== null ? this.authUser.email : "";
    },
    userId() {
      return this.authUser !== null ? this.authUser.id : "";
    },
    analytics() {
      return import.meta.env.VITE_MATOMO_TM === "true";
    },
  },
  methods: {
    ...mapActions("userPrefs", ["setUserPrefLang", "setUserPrefTimezone"]),
    ...mapMutations("organisations", ["checkIfOrgIsConnected"]),
    ...mapMutations("authentication", ["setCallbackID"]),
    modalClose() {
      if (this.callbackID) {
        this.checkIfOrgIsConnected(this.callbackID);
        this.setCallbackID(null);
      }
    },
  },
};
</script>
