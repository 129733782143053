/* c8 ignore start */
import { PHISHING_SIMULATION } from "@/utils/services";

export const mspRoutes = {
  path: "/msp",
  component: () => import("@/layouts/Msp/MspLayout.vue"),
  children: [
    {
      path: "phishing_simulation",
      name: "msp-phishing_simulation",
      component: () => import("@/modules/PhishingSimulations/views/Msp"),
      redirect: {
        name: "msp-phishing_simulation-overview",
      },
      meta: {
        solutionId: PHISHING_SIMULATION,
      },
      children: [
        {
          path: "overview",
          name: "msp-phishing_simulation-overview",
          component: () =>
            import("@/modules/PhishingSimulations/views/Msp/Overview"),
        },
        {
          path: "overview/details",
          name: "msp-phishing_simulation-overview-details",
          component: () =>
            import(
              "@/modules/PhishingSimulations/views/Msp/CustomerCampaignDetails"
            ),
        },
        {
          path: "phishing_campaigns",
          name: "msp-phishing_simulation-phishing_campaigns",
          component: () =>
            import("@/modules/PhishingSimulations/views/Msp/PhishingCampaigns"),
        },
      ],
    },
  ],
};

export default {};
/* c8 ignore end */
