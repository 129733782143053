import { $http } from "@/plugins/api";
import { Commit } from "vuex";
import { RemediationSettingsStatus } from "../types/remediation";

interface RemediationSettings {
  status: {
    value: boolean;
    inherited_from: string | null;
  };
}

interface RemediationState {
  settings?: RemediationSettings;
}

const state: RemediationState = {
  settings: undefined,
};

const mapSettingsValueToStatus = (
  value?: boolean,
): RemediationSettingsStatus => (value ? "auto" : "manual");

const mapStatusToSettingsValue = (status: RemediationSettingsStatus) =>
  status === "auto" ? true : false;

const getters = {
  settingsStatus: (state: RemediationState) =>
    mapSettingsValueToStatus(state.settings?.status.value),
};

const actions = {
  async getRemediationSettings(
    { commit }: { commit: Commit },
    {
      organisationID,
    }: {
      organisationID: string;
    },
  ) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/settings/${organisationID}/autoremediation`,
    );

    commit("setRemediationSettings", res.data);
  },
  async putRemediationSettings(
    { commit }: { commit: Commit },
    {
      organisationID,
      status,
    }: {
      organisationID: string;
      status: RemediationSettingsStatus;
    },
  ) {
    const isAutoRemediation = mapStatusToSettingsValue(status);

    const res = await $http.put(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/settings/${organisationID}/autoremediation`,
      {
        status: isAutoRemediation,
      },
    );

    commit("setRemediationSettings", res.data);
  },
};

const mutations = {
  setRemediationSettings: (
    state: RemediationState,
    data: RemediationSettings,
  ) => (state.settings = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
