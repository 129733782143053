/* c8 ignore start */
import { i18n } from "@/i18n.js";
import { SIDENAV_GROUP_BY } from "@/constants";
import { SAT_SERVICE } from "@/utils/services";

export const MSP_NAV_ITEMS = [
  {
    order: 2,
    groupBy: SIDENAV_GROUP_BY.BETA,
    label: i18n.global.t("sidebar-security-awareness-training"),
    icon: "cl-icon-tabler-school",
    id: "/msp/security_awareness_training",
    dataTestId: "msp-security_awareness_training",
    solutionId: SAT_SERVICE,
    permanentPill: { variant: "success", text: i18n.global.t("New") },
    children: [
      {
        label: i18n.global.t("sidebar-overview"),
        id: "/msp/security_awareness_training/overview",
        dataTestId: "msp-security_awareness_training-overview",
      },
      {
        label: i18n.global.t("sidebar-sat-campaigns"),
        id: "/msp/security_awareness_training/sat_campaigns",
        dataTestId: "msp-security_awareness_training-sat_campaigns",
        disabled: true,
      },
    ],
  },
];

export const CUSTOMER_NAV_ITEMS = [
  {
    order: 2,
    groupBy: SIDENAV_GROUP_BY.BETA,
    label: i18n.global.t("sidebar-security-awareness-training"),
    icon: "cl-icon-tabler-school",
    id: "/customer/security_awareness_training",
    dataTestId: "customer-security_awareness_training",
    solutionId: SAT_SERVICE,
    permanentPill: { variant: "success", text: i18n.global.t("New") },
    children: [
      {
        label: i18n.global.t("sidebar-overview"),
        id: "/customer/security_awareness_training/overview",
        dataTestId: "customer-security_awareness_training-overview",
      },
      {
        label: i18n.global.t("sidebar-sat-campaigns"),
        id: "/customer/security_awareness_training/sat_campaigns",
        dataTestId: "customer-security_awareness_training-sat_campaigns",
        disabled: true,
      },
    ],
  },
];
/* c8 ignore end */
