import { mspRoutes, customerRoutes } from "./routes";
import modules from "./store";

import { NAV_ITEMS_MODE } from "@/constants";
import { MSP_NAV_ITEMS, CUSTOMER_NAV_ITEMS } from "./constants";

const PhishTitanModule = ({ router, registerNavItems, store }) => {
  // Routes
  router.addRoute(mspRoutes);
  router.addRoute(customerRoutes);

  store.registerModule("resolutionHistory", modules.resolutionHistory);
  store.registerModule("targetedUsers", modules.targetedUsers);
  store.registerModule("incidents", modules.incidents, {
    preserveState: !!store._state.data.incidents,
  });
  store.registerModule("incidentSummary", modules.incidentSummary);
  store.registerModule("linkLock", modules.linkLock);
  store.registerModule("notifications", modules.notifications);

  // Nav Items
  registerNavItems(NAV_ITEMS_MODE.MSP, MSP_NAV_ITEMS, CUSTOMER_NAV_ITEMS);
  registerNavItems(NAV_ITEMS_MODE.CUSTOMER_ADMIN, CUSTOMER_NAV_ITEMS);
};

export { PhishTitanModule };

export default {};
