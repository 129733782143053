import { $http } from "@/plugins/api";
import type { Commit } from "vuex";

interface State {
  link: string | null;
}

const state: State = {
  link: null,
};

export const getters = {
  getLink: (state: State) => state.link,
};

export const actions = {
  async fetchArcTitanLoginUrl(
    { commit }: { commit: Commit },
    {
      organisationID,
      token,
    }: {
      organisationID: string;
      token: string;
    },
  ) {
    const res = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/arctitan/${organisationID}/login/url`,
    );
    const link = res.data.url + token;

    commit("setLink", link);
  },
};

export const mutations = {
  setLink: (state: State, data: never) => (state.link = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
