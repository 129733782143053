<template>
  <Teleport to="body">
    <cl-modal
      :header-label="$t('Allowed URL')"
      :visible="showModal"
      @on-close="removeModal"
    >
      <form autocomplete="off" @submit.prevent="submit">
        <div class="tw-mx-3 tw-mb-3 tw-grid tw-grid-cols-1 tw-gap-5">
          <div class="w-full w-2/4 px-2 mb-2 md:mb-0 tw-col-span-1">
            <cl-form-group>
              <cl-form-label :label-for="'url-input'">
                {{ $t("Allowed URL") }}
              </cl-form-label>
              <cl-form-input
                v-model:value="v$.form.url.$model"
                :name="'url-input'"
                :type="'text'"
                :placeholder="$t('https://www.example.com')"
                :data-test-id="`add-url`"
                :state="!v$.form.url.$dirty ? null : !v$.form.url.$error"
              />
            </cl-form-group>
            <span
              v-if="v$.form.url.$dirty && v$.form.url.required.$invalid"
              class="tw-text-danger-500"
              >{{
                $t("Please enter a valid URL including http:// or https://")
              }}</span
            >

            <span
              v-if="v$.form.url.$dirty && v$.form.url.url.$invalid"
              class="tw-text-danger-500"
              >{{
                $t("Please enter a valid URL including http:// or https://")
              }}</span
            >
          </div>
        </div>
        <div class="tw-mx-3 tw-mb-3 tw-grid tw-grid-cols-1 tw-gap-5">
          <div class="w-full w-2/4 px-2 mb-2 md:mb-0 tw-col-span-1">
            <cl-form-group>
              <cl-form-label :label-for="'description'">
                {{ $t("Comments (optional)") }}
              </cl-form-label>
              <cl-form-input
                id="description"
                v-model:value="form.description"
                :name="'description'"
                :type="'text'"
                :data-test-id="`add-description`"
                :placeholder="$t('Comments (optional)')"
              />
            </cl-form-group>
          </div>
        </div>
      </form>
      <template #[`footer.decline`]>
        <cl-button
          data-test-id="add-allowed-url-cancel-btn"
          variant="link-secondary"
          :disabled="fetching"
          @on-click="removeModal"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template #[`footer.accept`]>
        <cl-button v-if="fetching" :variant="'secondary'" disabled>
          <cl-spinner :size="'small'" class="mr-2" />
          <span>Saving...</span>
        </cl-button>
        <cl-button
          v-else
          data-test-id="add-allowed-url-add-btn"
          :variant="'secondary'"
          @on-click="submit"
        >
          <span>{{ $t("Add") }}</span>
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, url } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      fetching: false,
      form: {
        url: "",
        strict: true,
        description: "",
      },
    };
  },
  validations() {
    return {
      form: {
        url: { required, url },
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal", "modalData"]),
  },
  methods: {
    ...mapActions("modal", ["closeModal", "clearModalCache"]),
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("linkLock", ["createLinkLockExemption"]),
    removeModal() {
      for (const key in this.form) {
        this.form[key] = "";
      }
      this.v$.form.$reset();

      this.clearModalCache();
      this.closeModal();
    },
    async submit() {
      const isValid = await this.v$.form.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        await this.createLinkLockExemption({
          id: this.modalData.id,
          formData: this.form,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Created"),
          duration: 2000,
          variant: "success",
        });

        this.removeModal();
      } catch (error) {
        // Handled in axios interceptor
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
