import { $http } from "@/plugins/api";

const getDefaultState = () => {
  return {
    policy: {},
    exemptions: [],
  };
};

const state = getDefaultState();

const getters = {
  policy: (state) => state.policy,
  linkLockStatus: (state) => {
    // If the Policy is exist return the status
    if (Object.hasOwn(state.policy, "enableRewrite")) {
      return state.policy.enableRewrite;
    }

    // Otherwise null, as not exist
    return null;
  },
  exemptions: (state) => state.exemptions,
};

const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },
  async fetchLinkLockPolicy({ commit }, { organisationID }) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/linklock/options/org/${organisationID}`,
    );
    commit("setLinkLockPolicy", res.data);
  },
  async createLinkLockPolicy({ commit }, { organisationID, formData }) {
    const res = await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/linklock/options/org/${organisationID}`,
      formData,
    );

    commit("setLinkLockPolicy", res.data);
  },
  async updateLinkLockPolicyBlockImage(_context, { organisationID, formData }) {
    await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/linklock/image/org/${organisationID}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  },
  async updateLinkLockPolicy({ commit }, { organisationID, formData }) {
    const res = await $http.put(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/linklock/options/org/${organisationID}`,
      formData,
    );

    commit("setLinkLockPolicy", res.data);
  },
  async fetchLinkLockExemptions({ commit }, { id }) {
    const url = `${
      import.meta.env.VITE_API_CLIENT
    }/pt/linklock/exemption/org/${id}`;
    const res = await $http.get(url);

    commit("setLinkLockExemptions", res.data);
  },
  async createLinkLockExemption({ commit }, { id, formData }) {
    const url = `${
      import.meta.env.VITE_API_CLIENT
    }/pt/linklock/exemption/org/${id}`;

    const res = await $http.post(url, formData);

    commit("addLinkLockExemption", res.data);
  },
  async updateLinkLockExemption({ commit }, { id, exemptionId, formData }) {
    const url = `${
      import.meta.env.VITE_API_CLIENT
    }/pt/linklock/exemption/org/${id}/${exemptionId}`;
    const res = await $http.put(url, formData);

    commit("updateLinkLockExemption", res.data);
  },
  async batchDeleteLinkLockExemptions({ commit }, { id, exemptions }) {
    const entries = [];

    for (const exemption of exemptions) {
      const key = `${exemption.id}`;
      entries.push(key);
    }
    const res = await $http.delete(
      `${import.meta.env.VITE_API_CLIENT}/pt/linklock/exemption/org/${id}`,
      {
        data: entries,
      },
    );
    if (res.status === 204 || res.status === 200) {
      const ids = entries.map((id) => id);
      commit("deleteExemptions", ids);
    }
  },

  clearList({ commit }) {
    commit("setLinkLockExemptions", []);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setLinkLockPolicy: (state, data) => (state.policy = data),
  setLinkLockExemptions: (state, data) => (state.exemptions = data),
  addLinkLockExemption: (state, data) => state.exemptions.push(data),
  updateLinkLockExemption: (state, data) => {
    const index = state.exemptions.findIndex(
      (exemption) => exemption.id === data.id,
    );

    if (index !== -1) {
      state.exemptions[index] = data;
    }
  },
  deleteExemptions(state, ids) {
    for (const id of ids) {
      state.exemptions = state.exemptions.filter(
        (exemption) => exemption.id !== id,
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
