import { computed } from "vue";
import { useStore } from "vuex";

import type { Ref } from "vue";

export const useGlobalOrganisationID = () => {
  const store = useStore();
  const selectedMsp = computed(() => store.state.authentication.selectedMsp);
  const selectedCustomer = computed(
    () => store.state.authentication.selectedCustomer,
  );
  const selectedAccount = computed(
    () => store.state.authentication.selectedAccount,
  );

  const mspGlobalOrganisationId: Ref<string | null> = computed(
    () => selectedMsp.value.uuid || selectedAccount.value.uuid || null,
  );
  const customerGlobalOrganisationId: Ref<string | null> = computed(
    () => selectedCustomer.value?.uuid || selectedAccount.value?.uuid || null,
  );

  return {
    mspGlobalOrganisationId,
    customerGlobalOrganisationId,
  };
};

export default {};
