import { Collection } from "@/types/api";
import { $http } from "@/plugins/api";
import {
  type SenderToAllow,
  type Sender,
  type SenderToEdit,
  type SendersToDelete,
  type SendersToGet,
  isEmailToAllow,
  isEmailToEdit,
  type FreeDomain,
} from "@/types/allowBlock";
import type { Commit } from "vuex";

const ALLOW = "allow";

interface AllowBlockState {
  collection: Collection<Sender>;
  freeProviderDomains: FreeDomain[];
}

const defaultCollection = {
  current_page: 1,
  page_size: 10,
  results: [],
  total: 0,
};

const state: AllowBlockState = {
  collection: defaultCollection,
  freeProviderDomains: [],
};

const getters = {
  collection: (state: AllowBlockState) => state.collection,
  freeProviderDomains: (state: AllowBlockState) =>
    state.freeProviderDomains.map((domain) => domain.value),
};

const actions = {
  async postAllowedSender(_: unknown, sender: SenderToAllow) {
    const value = isEmailToAllow(sender) ? sender.email : sender.domain;

    await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/allow-block/${sender.organisationID}/actions/${sender.type}`,
      {
        value,
        comment: sender.comment || "",
        action: ALLOW,
      },
      { _hasCustomErrorHandling: true },
    );
  },
  async getAllowedSenders(
    { commit }: { commit: Commit },
    { organisationID, params, type }: SendersToGet,
  ) {
    const queryString = new URLSearchParams(params).toString();

    try {
      const res = await $http.get(
        `${
          import.meta.env.VITE_API_CLIENT
        }/allow-block/${organisationID}/actions/${type}?${queryString}`,
      );

      commit("setCollection", res.data);
    } catch (err) {
      commit("setCollection", defaultCollection);
    }
  },
  async patchAllowedSender(_: unknown, sender: SenderToEdit) {
    const value = isEmailToEdit(sender) ? sender.email : sender.domain;

    await $http.patch(
      `${
        import.meta.env.VITE_API_CLIENT
      }/allow-block/${sender.organisationID}/actions/${sender.type}/${sender.id}`,
      {
        value,
        comment: sender.comment || "",
      },
      { _hasCustomErrorHandling: true },
    );
  },
  async deleteAllowedSenders(
    _: unknown,
    { organisationID, ids, type }: SendersToDelete,
  ) {
    await $http.delete(
      `${
        import.meta.env.VITE_API_CLIENT
      }/allow-block/${organisationID}/actions/${type}`,
      {
        data: { ids },
        _hasCustomErrorHandling: true,
      },
    );
  },
  async getFreeProviderDomains({ commit }: { commit: Commit }) {
    const TITAN_GLOBAL_ORGANISATION_ID = "0c9706a0-86a1-46bf-b8ac-7ea52c2db44b";
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/allow-block/${TITAN_GLOBAL_ORGANISATION_ID}/actions/domain/free`,
    );

    commit("setFreeProviderDomains", res.data);
  },
};

const mutations = {
  setCollection: (state: AllowBlockState, data: Collection<Sender>) =>
    (state.collection = data),
  setFreeProviderDomains: (
    state: AllowBlockState,
    data: Collection<FreeDomain>,
  ) => (state.freeProviderDomains = data.results),
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
