const defaultStateModal = () => {
  return {
    showModal: false,
    data: null,
    step: "",
  };
};

const state = defaultStateModal();

const getters = {
  showModal: (state) => state.showModal,
  modalData: (state) => state.data,
  step: (state) => state.step,
};

const actions = {
  clearModalCache({ commit }) {
    commit("resetState");
  },
  openModal: ({ state }) => {
    state.showModal = true;
  },
  setModalStep: ({ state }, position) => {
    state.step = position;
  },
  closeModal: ({ state }) => {
    state.showModal = false;
    state.step = "FIRST";
  },
  setModalData: ({ state }, data) => {
    state.data = data;
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, defaultStateModal());
  },
};
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
