/* c8 ignore start */
import { mspRoutes, customerRoutes } from "./routes";

import modules from "./store";

import { useFlag } from "@/composables/useFlags/useFlag";
import { NAV_ITEMS_MODE } from "@/constants";

import { MSP_NAV_ITEMS, CUSTOMER_NAV_ITEMS } from "./constants";

const { isFeatureAvailable: isCustomerPhSAvailable } = useFlag(
  "PHISHING_SIMULATIONS_CUSTOMER",
);

const PhishingSimulationsModule = ({ router, store, registerNavItems }) => {
  // Routes
  router.addRoute(mspRoutes);

  if (isCustomerPhSAvailable.value) {
    router.addRoute(customerRoutes);
  }

  // Stores
  store.registerModule("phs_simulations", modules.simulations);
  store.registerModule("phs_lures", modules.lures);
  store.registerModule("phs_customers", modules.customers);
  store.registerModule("phs_campaigns", modules.campaigns);
  store.registerModule("phs_users", modules.users);
  store.registerModule("phs_cards", modules.cards);

  // Nav Items
  registerNavItems(NAV_ITEMS_MODE.MSP, MSP_NAV_ITEMS);

  if (isCustomerPhSAvailable.value) {
    registerNavItems(NAV_ITEMS_MODE.CUSTOMER_ADMIN, CUSTOMER_NAV_ITEMS);
  }
};

export { PhishingSimulationsModule };

export default {};
/* c8 ignore end */
