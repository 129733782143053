import { mspRoutes, customerRoutes } from "./routes";
import modules from "./store";

import { NAV_ITEMS_MODE } from "@/constants";
import { MSP_NAV_ITEMS, CUSTOMER_NAV_ITEMS } from "./constants";

const LicenceUsageModule = ({ router, store, registerNavItems }) => {
  // Routes
  router.addRoute(mspRoutes);
  router.addRoute(customerRoutes);

  // Store
  store.registerModule("licence", modules.licence);

  // Nav Items
  registerNavItems(NAV_ITEMS_MODE.MSP, MSP_NAV_ITEMS);
  registerNavItems(NAV_ITEMS_MODE.CUSTOMER_ADMIN, CUSTOMER_NAV_ITEMS);
};

export { LicenceUsageModule };

export default {};
