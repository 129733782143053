<template>
  <nav
    v-for="[key, group] in Object.entries(navMenuSetUp)"
    :key="key"
    :class="{ 'first:tw-hidden': isCustomer }"
    class="tw-bg-aside-600 tw-relative"
  >
    <ul>
      <li
        v-for="firstLevelItem in group"
        :key="firstLevelItem.id"
        class="tw-group tw-relative dark:text-white"
      >
        <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
          :to="firstLevelItem.id"
        >
          <a
            :href="href"
            :class="{
              'tw-bg-aside-400 tw-font-normal tw-shadow-[inset_0.1875rem_0] tw-shadow-aside-300':
                !isExactActive && isActive,
              'tw-bg-aside-400 tw-font-bold tw-shadow-[inset_0.1875rem_0] tw-shadow-white dark:tw-shadow-dorian-gray-500':
                isExactActive && isActive,
              'tw-pointer-events-none tw-text-dorian-gray-200':
                firstLevelItem.disabled,
            }"
            class="tw-flex tw-py-4 tw-px-4 hover:tw-bg-aside-400 tw-transition-colors"
            :data-test-id="firstLevelItem.dataTestId"
            custom
            @click="navigate"
          >
            <div class="tw-grid tw-grid-cols-12 tw-gap-0 tw-w-full">
              <div class="tw-col-span-2">
                <component :is="firstLevelItem.icon" />
              </div>
              <div
                class="tw-col-span-10 tw-flex tw-justify-between tw-items-center"
                :class="{
                  'tw-invisible tw-h-0': isSidebarCollapsed,
                }"
              >
                <span>{{ firstLevelItem.label }}</span>
                <cl-pill
                  v-if="firstLevelItem.permanentPill"
                  :variant="firstLevelItem.permanentPill.variant"
                  >{{ firstLevelItem.permanentPill.text }}</cl-pill
                >
                <cl-pill
                  v-if="firstLevelItem.pill"
                  :variant="firstLevelItem.pill.variant"
                  >{{ firstLevelItem.pill.text }}</cl-pill
                >
                <cl-icon-tabler-external-link
                  v-if="firstLevelItem.isExternal"
                />
              </div>
            </div>
          </a>
          <div
            v-if="
              firstLevelItem.children?.length >= 1 &&
              shouldCollapseSidebar(isSidebarCollapsed, isActive)
            "
            class="tw-bg-aside-400 tw-shadow-[inset_0.1875rem_0] tw-shadow-aside-300"
            :class="
              isSidebarCollapsed
                ? 'group-hover:tw-block tw-left-[56px] tw-top-0 tw-absolute tw-hidden tw-w-[240px]'
                : 'tw-block'
            "
          >
            <ul class="tw-group">
              <li
                v-for="secondLevelItem in firstLevelItem.children"
                :key="secondLevelItem.id"
                class="tw-group"
              >
                <router-link
                  v-slot="{
                    href: href2,
                    navigate: navigate2,
                    isActive: isActive2,
                    isExactActive: isExactActive2,
                  }"
                  custom
                  :to="secondLevelItem.id"
                >
                  <a
                    :href="href2"
                    :class="{
                      'tw-bg-aside-300 tw-font-normal tw-shadow-[inset_0.1875rem_0] tw-shadow-aside-300':
                        !isExactActive2 && isActive2,
                      'tw-bg-aside-300 tw-font-bold tw-shadow-[inset_0.1875rem_0] tw-shadow-white dark:tw-shadow-dorian-gray-500':
                        isExactActive2 && isActive2,
                      'tw-pointer-events-none tw-text-dorian-gray-200':
                        secondLevelItem.disabled,
                    }"
                    class="tw-flex tw-relative tw-py-4 tw-px-4 hover:tw-bg-aside-300 tw-transition-colors"
                    :data-test-id="secondLevelItem.dataTestId"
                    custom
                    @click="navigate2"
                  >
                    <div class="tw-grid tw-grid-cols-12 tw-gap-0 tw-w-full">
                      <div
                        class="tw-col-start-3 tw-col-span-9 tw-flex tw-justify-between tw-items-center"
                      >
                        <span>{{ secondLevelItem.label }}</span>
                        <cl-pill
                          v-if="secondLevelItem.pill && !isActive2"
                          :variant="secondLevelItem.pill.variant"
                          >{{ secondLevelItem.pill.text }}</cl-pill
                        >
                        <cl-pill
                          v-if="secondLevelItem.permanentPill"
                          :variant="secondLevelItem.permanentPill.variant"
                          >{{ secondLevelItem.permanentPill.text }}</cl-pill
                        >
                      </div>
                      <div class="tw-col-span-1">
                        <cl-icon-tabler-chevron-down
                          v-if="secondLevelItem.children?.length >= 1"
                          :class="{ 'tw-rotate-180': isActive2 }"
                          class="tw-transition-transform"
                        />
                      </div>
                    </div>
                  </a>
                  <div
                    v-if="secondLevelItem.children?.length >= 1 && isActive2"
                    class="tw-bg-aside-300"
                    :class="'tw-shadow-[inset_0.1875rem_0] tw-shadow-aside-300'"
                  >
                    <ul>
                      <li
                        v-for="thirdLevelItem in secondLevelItem.children"
                        :key="thirdLevelItem.id"
                      >
                        <router-link
                          v-slot="{
                            href: href3,
                            navigate: navigate3,
                            isActive: isActive3,
                          }"
                          :to="thirdLevelItem.id"
                          custom
                        >
                          <a
                            :href="href3"
                            :class="{
                              'tw-bg-aside-300   tw-font-normal tw-shadow-[inset_0.1875rem_0] tw-shadow-aside-300':
                                !isActive3,
                              'tw-bg-[#4E5670] dark:tw-bg-aside-300  tw-font-bold tw-shadow-[inset_0.1875rem_0] tw-shadow-white dark:tw-shadow-dorian-gray-500':
                                isActive3,
                              'tw-pointer-events-none tw-text-dorian-gray-200':
                                thirdLevelItem.disabled,
                            }"
                            class="tw-flex tw-relative tw-py-4 tw-px-4 dark:hover:tw-bg-aside-400 hover:tw-bg-[#4E5670] tw-transition-colors"
                            :data-test-id="thirdLevelItem.dataTestId"
                            @click="navigate3"
                          >
                            <div
                              class="tw-grid tw-grid-cols-12 tw-gap-0 tw-w-full"
                            >
                              <div
                                class="tw-col-start-4 tw-col-span-9 tw-flex tw-justify-between tw-items-center"
                              >
                                <span>{{ thirdLevelItem.label }}</span>
                                <cl-pill
                                  v-if="thirdLevelItem.pill"
                                  :variant="thirdLevelItem.pill.variant"
                                  >{{ thirdLevelItem.pill.text }}</cl-pill
                                >
                              </div>
                            </div>
                          </a>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </router-link>
      </li>
    </ul>
    <div class="tw-my-2 tw-px-4 tw-py-2">
      <hr class="tw-border-aside-400 dark:tw-border-dorian-gray-100" />
    </div>
  </nav>
</template>
<script setup lang="ts">
import { toRefs, computed } from "vue";
import { TIER } from "@/constants";
import { TSideNavProps } from "./types";

const props = defineProps<TSideNavProps>();
const { navMenuSetUp, isSidebarCollapsed, tier } = toRefs(props);

const isCustomer = computed(() => {
  return tier.value === TIER.customer;
});
const shouldCollapseSidebar = (
  isSidebarCollapsed: boolean,
  isActive: boolean,
) => {
  if (isActive && !isSidebarCollapsed) {
    return true;
  } else if (!isActive && !isSidebarCollapsed) {
    return false;
  } else if (isActive && isSidebarCollapsed) {
    return true;
  } else if (!isActive && isSidebarCollapsed) {
    return true;
  }
};
</script>
