import { $http } from "@/plugins/api";
import type { Commit } from "vuex";
import { Organisation } from "../../types/organisatio";
import { Params } from "@/types/api";

interface State {
  list: Organisation[];
}

const state: State = {
  list: [],
};

const getters = {
  dropDownList: (state: { list: State }) => state.list,
};

const actions = {
  resetState({ commit }: { commit: Commit }) {
    commit("dropDownList", []);
  },
  async getDropDownList(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      params,
    }: {
      globalOrganisationID: string | null;
      params: Params;
    },
  ) {
    const url = new URL(`${import.meta.env.VITE_API_CLIENT}/idp/organisations`);

    const reqParams = new URLSearchParams();

    if (globalOrganisationID) {
      reqParams.append("parent_uuid", globalOrganisationID);
    }

    // Convert params to lowercase strings and append them to reqParams
    for (const [key, value] of Object.entries(params)) {
      const encodedValue = encodeURIComponent(value.toString());
      reqParams.append(key, encodedValue);
    }

    url.search = reqParams.toString();

    const orgsResponse = await $http.get(url.toString());

    commit("dropDownList", orgsResponse.data.data);
  },
};

const mutations = {
  dropDownList: (state: { list: Organisation }, list: Organisation) =>
    (state.list = list),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
