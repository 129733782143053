import { $http } from "@/plugins/api";
import { Commit } from "vuex";
import {
  IncidentSummary,
  IncidentDetails,
  AffectedUser,
  ReceivedHeader,
} from "../types/IncidentSummary";

interface IncidentSummaryState {
  incidentSummary: IncidentSummary | null;
  incidentDetails: IncidentDetails | null;
  affectedUsers: AffectedUser[];
  receivedHeaders: ReceivedHeader[];
}
type IncidentActionPayload = {
  globalOrganisationID: string;
  internetMessageID: string;
  mailSpMessageID: string;
};

type UserActionPayload = {
  globalOrganisationID: string;
  internetMessageID: string;
  customerAccountID: string;
};

type HeaderActionPayload = {
  customerGlobalAccountID: string;
  mailbox: string;
  mailSpMessageID: string;
};

type StatusActionPayload = {
  globalOrganisationID: string;
  internetMessageID: string;
  mailSpMessageID: string;
  status: string;
};

const state: IncidentSummaryState = {
  incidentSummary: null,
  incidentDetails: null,
  affectedUsers: [],
  receivedHeaders: [],
};

const getters = {
  incidentSummary: (state: IncidentSummaryState) => state.incidentSummary,
  incidentDetails: (state: IncidentSummaryState) => state.incidentDetails,
  affectedUsers: (state: IncidentSummaryState) => state.affectedUsers,
  receivedHeaders: (state: IncidentSummaryState) => state.receivedHeaders,
};

const actions = {
  async getIncidentSummary(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      internetMessageID,
      mailSpMessageID,
    }: IncidentActionPayload,
  ) {
    commit("setIncidentSummary", null);
    const encodedMailSpMessageID = encodeURIComponent(mailSpMessageID);
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/history/${globalOrganisationID}/incidents/${internetMessageID}/${encodedMailSpMessageID}`,
    );

    commit("setIncidentSummary", res.data);
  },
  async getIncidentDetails(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      internetMessageID,
      mailSpMessageID,
    }: IncidentActionPayload,
  ) {
    commit("setIncidentDetails", null);
    const encodedMailSpMessageID = encodeURIComponent(mailSpMessageID);

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/history/${globalOrganisationID}/incidents/${internetMessageID}/${encodedMailSpMessageID}/details`,
    );

    commit("setIncidentDetails", res.data);
  },
  async getAffectedUsers(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      internetMessageID,
      customerAccountID,
    }: UserActionPayload,
  ) {
    commit("setAffectedUsers", []);

    let url = "";

    if (customerAccountID) {
      url = `${
        import.meta.env.VITE_API_CLIENT
      }/pt/msg/${customerAccountID}/incidents/${internetMessageID}/affected-users`;
    } else {
      url = `${
        import.meta.env.VITE_API_CLIENT
      }/pt/msg/${globalOrganisationID}/incidents/${internetMessageID}/affected-users`;
    }

    const res = await $http.get(url);

    commit("setAffectedUsers", res.data);
  },
  async getReceivedHeaders(
    { commit }: { commit: Commit },
    { customerGlobalAccountID, mailbox, mailSpMessageID }: HeaderActionPayload,
  ) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/mail-message/${customerGlobalAccountID}/${mailbox}/${mailSpMessageID}/headers`,
    );

    commit("setReceivedHeaders", res.data);
  },
  async updateIncidentStatus(
    { commit, state }: { commit: Commit; state: IncidentSummaryState },
    {
      globalOrganisationID,
      internetMessageID,
      mailSpMessageID,
      status,
    }: StatusActionPayload,
  ) {
    status = status === "AdminRemediated_NonPhish" ? "safe" : "junk";

    const res = await $http.put(
      `${
        import.meta.env.VITE_API_CLIENT
      }/pt/msg/${globalOrganisationID}/incidents/${internetMessageID}/${mailSpMessageID}/status`,
      {
        status,
      },
    );

    commit("setIncidentSummary", {
      ...state.incidentSummary,
      status:
        res.data.status === "junk"
          ? "AdminRemediated_Phish"
          : "AdminRemediated_NonPhish",
    });
    return res.data.data;
  },
};

const mutations = {
  setIncidentDetails: (state: IncidentSummaryState, data: IncidentDetails) =>
    (state.incidentDetails = data),
  setIncidentSummary: (state: IncidentSummaryState, data: IncidentSummary) =>
    (state.incidentSummary = data),
  setAffectedUsers: (state: IncidentSummaryState, data: AffectedUser[]) =>
    (state.affectedUsers = data),
  setReceivedHeaders: (state: IncidentSummaryState, data: ReceivedHeader[]) =>
    (state.receivedHeaders = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
