import { USER_ROLES } from "@/constants";
import { PHISH_SERVICE } from "@/utils/services";

export const customerRoutes = {
  path: "/customer",
  component: () => import("@/layouts/Customer/CustomerLayout.vue"),
  children: [
    {
      path: "phish_titan",
      name: "customer-phish_titan",
      component: () => import("@/modules/PhishTitan/views/Customer"),
      meta: {
        solutionId: PHISH_SERVICE,
      },
      redirect: {
        name: "customer-phish_titan-overview",
      },
      children: [
        {
          path: "overview",
          name: "customer-phish_titan-overview",
          component: () =>
            import("@/modules/PhishTitan/views/Customer/Overview"),
          exact: true,
          meta: {
            authorize: [USER_ROLES.SYSTEM, USER_ROLES.SALES, USER_ROLES.MSP],
          },
        },
        {
          path: "reports",
          name: "customer-phish_titan-reports",
          exact: true,
          component: () =>
            import("@/modules/PhishTitan/views/Customer/Reports"),
          redirect: {
            name: "insightsCustomerRoute",
          },
          children: [
            {
              path: "",
              name: "insightsCustomerRoute",
              component: () =>
                import("@/modules/PhishTitan/views/Customer/Reports/PowerBi"),
              redirect: {
                name: "summaryCustomerReport",
              },
              children: [
                {
                  path: "summary",
                  name: "summaryCustomerReport",
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Customer/Reports/PowerBi/Summary"
                    ),
                },
                {
                  path: "insights",
                  name: "insightsCustomerReport",
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Customer/Reports/PowerBi/ThreatSurfaceReport"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "notifications",
          name: "customer-phish_titan-notifications",
          component: () =>
            import(
              "@/modules/PhishTitan/views/Customer/Notifications/index.vue"
            ),
        },
        {
          path: "configuration",
          name: "customer-phish_titan-configuration",
          exact: true,
          redirect: {
            name: "customer-configuration-mail-flow",
          },
          children: [
            {
              path: "mail_flow",
              name: "customer-configuration-mail-flow",
              component: () =>
                import(
                  "@/modules/PhishTitan/views/Customer/Configuration/MailFlow/MailFlow.vue"
                ),
              exact: true,
              redirect: {
                name: "customer-configuration-mail-flow-remediation",
              },
              children: [
                {
                  path: "allow_list",
                  name: "customer-configuration-mail-flow-allow-list",
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Customer/Configuration/AllowList/index.vue"
                    ),
                },
                {
                  path: "remediation",
                  name: "customer-configuration-mail-flow-remediation",
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Common/Remediation/index.vue"
                    ),
                },
                {
                  path: "exploited_domains",
                  name: "customer-configuration-mail-flow-exploited-domains",
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Common/ExploitedDomains/index.vue"
                    ),
                },
                {
                  path: "anti_spoofing",
                  name: "customer-configuration-mail-flow-anti-spoofing",
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Common/AntiSpoofing/AntiSpoofing.vue"
                    ),
                },
                {
                  path: "graymail",
                  name: "customer-configuration-mail-flow-graymail",
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Common/Graymail/Graymail.vue"
                    ),
                },
              ],
            },

            {
              path: "link_lock",
              name: "customer-phish_titan-config-link-lock",
              exact: true,
              component: () =>
                import(
                  "@/modules/PhishTitan/views/Customer/Configuration/LinkLock/index.vue"
                ),
              redirect: {
                name: "customer-link-lock-policy-configuration",
              },
              children: [
                {
                  path: "policy_configuration",
                  name: "customer-link-lock-policy-configuration",
                  exact: true,
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Customer/Configuration/LinkLock/Tabs/PolicyConfiguration/index.vue"
                    ),
                },
                {
                  path: "exemptions",
                  name: "customer-link-lock-exemptions-configuration",
                  exact: false,
                  component: () =>
                    import(
                      "@/modules/PhishTitan/views/Customer/Configuration/LinkLock/Tabs/Exemptions/index.vue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "notifications",
          name: "customer-email_security-notifications",
          component: () =>
            import(
              "@/modules/PhishTitan/views/Customer/Notifications/index.vue"
            ),
        },
        {
          path: "incidents",
          name: "customer-phish_titan-incidents",
          component: () =>
            import("@/modules/PhishTitan/views/Customer/Incidents/Overview"),
        },
        {
          path: "incidents/incident_summary",
          name: "customer-phish_titan-incidents-incident-summary",
          component: () =>
            import(
              "@/modules/PhishTitan/views/Customer/Incidents/IncidentSummary/IncidentSummary.vue"
            ),
          redirect: {
            name: "customer-phish_titan-incidents-incident-summary-details",
          },
          children: [
            {
              path: "details",
              name: "customer-phish_titan-incidents-incident-summary-details",
              component: () =>
                import(
                  "@/modules/PhishTitan/views/Customer/Incidents/IncidentSummary/Details"
                ),
            },
            {
              path: "threat-coach",
              name: "customer-phish_titan-incidents-incident-summary-threat-coach",
              component: () =>
                import(
                  "@/modules/PhishTitan/views/Common/Incidents/IncidentSummary/ThreatCoach/ThreatCoach"
                ),
            },
            {
              path: "affected-users",
              name: "customer-phish_titan-incidents-incident-summary-affected-users",
              component: () =>
                import(
                  "@/modules/PhishTitan/views/Customer/Incidents/IncidentSummary/AffectedUsers"
                ),
            },
            {
              path: "received-headers",
              name: "customer-phish_titan-incidents-incident-summary-received-headers",
              component: () =>
                import(
                  "@/modules/PhishTitan/views/Customer/Incidents/IncidentSummary/ReceivedHeaders"
                ),
            },
            {
              path: "timeline",
              name: "customer-phish_titan-incidents-incident-summary-incident-timeline",
              redirect: {
                name: "customer-phish_titan-incidents-incident-summary-details",
              },
            },
            {
              path: "audit-logs",
              name: "customer-phish_titan-incidents-incident-summary-audit-logs",
              redirect: {
                name: "customer-phish_titan-incidents-incident-summary-details",
              },
            },
            {
              path: "notes",
              name: "customer-phish_titan-incidents-incident-summary-audit-notes",
              redirect: {
                name: "customer-phish_titan-incidents-incident-summary-details",
              },
            },
          ],
        },
      ],
    },
  ],
};

export default {};
