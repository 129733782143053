import { $http } from "@/plugins/api";
import type { Commit } from "vuex";
import { ApiSender, ProtocolConfig } from "./types";

const state: ProtocolConfig = {
  organisation_uuid: "",
  enabled: false,
  default_login_enabled: false,
  identity_provider: {
    login_url: "",
    logout_url: "",
    entity_id: "",
    certificate: "string",
    default_login_enabled: false,
  },
  service_provider: {
    entity_id: "",
    consumer_url: "",
    logout_url: "",
  },
  ssoConfigured: {
    sso: true,
    configured: false,
  },
};

const actions = {
  async ssoConfiEnabled({ commit }: { commit: Commit }, { uuid }: ApiSender) {
    const ssoSaml = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/organisations/${uuid}/sso/saml/enabled`,
    );
    commit("setSamlEnabled", ssoSaml.data);

    return ssoSaml.data;
  },

  async showSSOconfig({ commit }: { commit: Commit }, { uuid }: ApiSender) {
    const ssoSaml = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/organisations/${uuid}/sso/saml`,
    );
    const response = ssoSaml.data.data.identity_provider;
    response.default_login_enabled = ssoSaml.data.data.default_login_enabled;

    commit("setSamlIdentityProvider", ssoSaml.data.data.identity_provider);
    return response;
  },
  async updateSamlSSO(
    { commit }: { commit: Commit },
    { uuid, data }: ApiSender,
  ) {
    const updateSsoSaml = await $http.patch(
      `${import.meta.env.VITE_API_CLIENT}/idp/organisations/${uuid}/sso/saml`,
      {
        enabled: data?.enabled,
        default_login_enabled: data?.default_login_enabled,
        identity_provider: {
          login_url: data?.identity_provider.login_url,
          logout_url: data?.identity_provider.logout_url,
          entity_id: data?.identity_provider.entity_id,
          certificate: btoa(data?.identity_provider.certificate || ""),
          attributes: {
            email: data?.identity_provider.attributes?.attr_claim,
          },
        },
        service_provider: {
          entity_id: data?.service_provider.entity_id,
          consumer_url: data?.service_provider.consumer_url,
          logout_url: data?.service_provider.logout_url,
        },
      },
    );
    commit("setSamlIdentityProvider", updateSsoSaml.data.identity_provider);
    return updateSsoSaml;
  },

  async createSamlSSO(
    { commit }: { commit: Commit },
    { uuid, data }: ApiSender,
  ) {
    const ssoSaml = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/idp/organisations/${uuid}/sso/saml`,
      {
        enabled: data?.enabled,
        default_login_enabled: false,
        identity_provider: {
          login_url: data?.identity_provider.login_url,
          logout_url: data?.identity_provider.logout_url,
          entity_id: data?.identity_provider.entity_id,
          certificate: btoa(data?.identity_provider.certificate || ""),
          attributes: {
            email: data?.identity_provider.attributes?.attr_claim,
          },
        },
        service_provider: {
          entity_id: data?.service_provider.entity_id,
          consumer_url: data?.service_provider.consumer_url,
          logout_url: data?.service_provider.logout_url,
        },
      },
    );
    commit("setSamlIdentityProvider", ssoSaml.data.identity_provider);
    return ssoSaml;
  },
};

const getters = {
  isSamlEnabled: (state: ProtocolConfig) => state.ssoConfigured.sso,
  isSamlConfigured: (state: ProtocolConfig) => state.ssoConfigured.configured,
  getServiceProvider: (state: ProtocolConfig) => state.service_provider,
  identityProvider: (state: ProtocolConfig) => state.identity_provider,
};

const mutations = {
  setSamlIdentityProvider: (state: ProtocolConfig, data: never) =>
    (state.identity_provider = data),
  setSamlEnabled: (state: ProtocolConfig, data: never) =>
    (state.ssoConfigured = data),
};
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
