import { $http } from "@/plugins/api";

import type { Commit } from "vuex";
import type { Collection } from "@/types/api";
import type { Simulation } from "./types";

interface State {
  simulations: Collection<Simulation>;
}

const state: State = {
  simulations: {
    current_page: 1,
    page_size: 5,
    results: [],
    total: 0,
  },
};

const getters = {
  simulations: (state: State) => state.simulations,
};

const actions = {
  async getSimulations(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      params,
    }: {
      globalOrganisationID: string | null;
      params: Record<string, string | number>;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const reqParams: Record<string, string> = {};

    for (const [key, value] of Object.entries(params)) {
      reqParams[key] = value.toString().toLocaleLowerCase();
    }

    const searchParams = new URLSearchParams(reqParams);
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/simulations?${searchParams.toString()}`,
    );

    commit("setSimulations", res.data);
  },
  async getAllSimulations(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
    }: {
      globalOrganisationID: string | null;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const searchParams = new URLSearchParams({ results: "99999" });
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/${globalOrganisationID}/simulations?${searchParams.toString()}`,
    );

    return res.data;
  },
};

const mutations = {
  setSimulations: (state: State, data: Collection<Simulation>) =>
    (state.simulations = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
