/* c8 ignore start */
import { SAT_SERVICE } from "@/utils/services";

export const mspRoutes = {
  path: "/msp",
  component: () => import("@/layouts/Msp/MspLayout.vue"),
  children: [
    {
      path: "security_awareness_training",
      name: "msp-security_awareness_training",
      component: () => import("@/modules/SecurityAwarnessTraining/views/Msp"),
      redirect: {
        name: "msp-security_awareness_training-overview",
      },
      meta: {
        solutionId: SAT_SERVICE,
      },
      children: [
        {
          path: "overview",
          name: "msp-security_awareness_training-overview",
          component: () =>
            import("@/modules/SecurityAwarnessTraining/views/Msp/Overview"),
        },
        {
          path: "overview/details",
          name: "msp-security_awareness_training-details",
          component: () =>
            import(
              "@/modules/SecurityAwarnessTraining/views/Msp/CustomerCampaignDetails"
            ),
        },
        {
          path: "sat_campaigns",
          name: "msp-security_awareness_training-sat_campaigns",
          component: () =>
            import("@/modules/SecurityAwarnessTraining/views/Msp/SatCampaigns"),
        },
      ],
    },
  ],
};

export default {};
/* c8 ignore end */
