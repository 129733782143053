import dayjs from "dayjs";

/**
 * @use v-format-date="`MMM D, YYYY`"
 */
const formatDate = {
  mounted(el, binding) {
    const text = el.textContent;

    el.textContent = dayjs(text).format(binding.value);
  },
};

export default formatDate;
