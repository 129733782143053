/* c8 ignore start */
import simulations from "./simulations";
import lures from "./lures";
import customers from "./customers";
import campaigns from "./campaigns";
import users from "./users";
import cards from "./cards";

export default {
  simulations,
  lures,
  customers,
  campaigns,
  users,
  cards,
};
/* c8 ignore end */
