import { USER_ROLES } from "@/constants";

const systemRoutes = [
  {
    path: "",
    redirect: "/system/dashboard",
    component: () => import("@/views/System/App.vue"),
    meta: {
      authorize: [USER_ROLES.SYSTEM, USER_ROLES.SALES],
    },
  },
  {
    path: "/system/settings",
    redirect: "/system/settings/saml-sso",
    children: [
      {
        path: "saml-sso",
        component: () => import("@/views/System/Settings/Saml/index.vue"),
      },
    ],
  },
  {
    path: "dashboard",
    name: "systemOverview",
    component: () => import("@/views/System/Overview/index.vue"),
  },
  {
    path: "msp",
    redirect: {
      name: "mspOverview",
    },
  },
  {
    path: "msp/overview",
    redirect: {
      name: "mspOverview",
    },
  },
  {
    path: "msp/settings",
    redirect: {
      name: "msp-settings-administrators",
    },
  },
  {
    path: "msp/customer/settings",
    redirect: {
      name: "customer-settings-administrators",
    },
  },
  {
    path: "msp/settings/administrators",
    redirect: {
      name: "msp-settings-administrators",
    },
  },
  {
    path: "msp/settings/notifications",
    redirect: {
      name: "msp-settings-notifications",
    },
  },
  {
    path: "msp/customer",
    redirect: {
      name: "customerOver",
    },
  },

  {
    path: "msp/phish_titan/incidents",
    redirect: {
      name: "msp-phish_titan-incidents",
    },
  },
  {
    path: "msp/phish_titan/incidents/incident_summary",
    redirect: {
      name: "msp-phish_titan-incidents-incident-summary",
    },
  },
  {
    path: "msp/customer/phish_titan",
    redirect: {
      name: "customer-phish_titan",
    },
  },
  {
    path: "msp/customer/phish_titan/incidents",
    redirect: {
      name: "customer-phish_titan-incidents",
    },
  },
  {
    path: "msp/customer/phish_titan/incidents/incident_summary",
    redirect: {
      name: "customer-phish_titan-incidents-incident-summary",
    },
  },
  {
    path: "msp/settings/link_lock",
    redirect: {
      name: "msp-link-lock",
    },
  },
  {
    path: "msp/customer/settings/link_lock",
    redirect: {
      name: "customer-link-lock",
    },
  },
];

export default systemRoutes;
