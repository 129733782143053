<template>
  <h1></h1>
</template>

<script>
export default {
  created() {
    this.$router.push({ name: "auth-sign-in" });
  },
};
</script>
