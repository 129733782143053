/**
 * Truncates text if textContent of element is less than max length
 * @use v-truncate Truncates on the default of 25 characters
 * @use v-truncate="10" Truncates on 10 characters
 */

const truncate = {
  mounted(el, binding) {
    const text = el.textContent;
    const maxLength = +binding.value || 25;

    if (text.length > maxLength)
      el.textContent = `${text.substring(0, maxLength)}...`;
  },
};

export default truncate;
