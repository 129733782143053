import { $http } from "@/plugins/api";

const defaultUsersState = () => {
  return {
    users: [],
    selectedUser: null,
    selectedTier: null,
    selectedTierId: null,
    azureUsers: null,
  };
};
const state = defaultUsersState();

const getters = {
  getUsers: (state) => state.users,
  selectedUserAccount: (state) => state.selectedUser,
  getSelectedTierId: (state) => state.selectedTierId,
  selectedUser: (state) => state.selectedUser,
  getAzureUsers: (state) => state.azureUsers,
};

const actions = {
  clearUsersCache({ commit }) {
    commit("resetState");
  },
  async updateUser({ commit }, formData) {
    const rs = await $http.patch(
      `${import.meta.env.VITE_API_CLIENT}/idp/users/${formData.uuid}`,
      {
        name: formData.formData.name,
        email: formData.formData.email,
        password: formData.formData.password,
      },
    );
    commit("updateUser", rs.data);
  },
  async getUserList({ commit }, organisation_uuid) {
    const rs = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/users?organisation_uuid=${organisation_uuid.organisation_uuid}`,
      {
        organisation_uuid: organisation_uuid.organisation_uuid,
      },
    );
    commit("setUsers", rs.data);
  },
  async deleteUsers({ commit }, { users }) {
    const response = await $http.delete(
      `${import.meta.env.VITE_API_CLIENT}/idp/users/${users[0].uuid}`,
    );

    commit("deleteUsers", [response.data.uuid]);
    return response;
  },
  async createUser({ commit }, { uuid, formData }) {
    const userResponse = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/idp/users`,
      {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        organisation_uuid: uuid,
      },
    );
    commit("updateUser", userResponse.data);
    return userResponse;
  },
  async azureUsers({ commit }, { id }) {
    const azureUsers = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/pt/users?organisationID=${id}&top=999`,
      { _hasCustomErrorHandling: true },
    );
    commit("setAzureUser", azureUsers.data.users);
  },
  async getUser({ commit }, uuid) {
    const id = uuid.uuid;
    const rs = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/users/${id}`,
    );

    commit("setselectedUser", rs.data);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, defaultUsersState());
  },
  setUsers: (state, data) => (state.users = data.data),
  setAzureUser: (state, data) => (state.azureUsers = data),
  updateUser: (state, data) => state.users.push(data),
  setselectedUser: (state, data) => (state.selectedUser = data),
  setSelectedTier: (state, data) => (state.selectedTier = data),
  setSelectedTierId: (state, data) => (state.selectedTierId = data),
  deleteUsers(state, ids) {
    for (const id of ids) {
      state.users = state.users.filter((user) => user.uuid !== id);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
