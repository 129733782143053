import { $http } from "@/plugins/api";

const defaultState = () => {
  return {
    webhook: null,
  };
};
const state = defaultState();

const getters = {
  webhook: (state) => state.webhook,
};

const actions = {
  async getWebhook({ commit }, organisationID) {
    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/notification/${organisationID}/gethook`,
    );

    commit("setWebhook", res.data);
  },

  async postWebhook({ commit }, { organisationID, name, destination }) {
    const encodedName = encodeURIComponent(name);
    const encodedDestination = encodeURIComponent(destination);

    await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/notification/${organisationID}/webhook/${encodedName}/${encodedDestination}`,
    );

    commit("updateWebhook", { name, destination });
  },

  async deleteWebhook({ commit }, { organisationID, destination }) {
    const encodedDestination = encodeURIComponent(destination);

    await $http.delete(
      `${
        import.meta.env.VITE_API_CLIENT
      }/notification/${organisationID}/deletehook/${encodedDestination}`,
    );

    commit("deleteWebhook");
  },
};

const mutations = {
  setWebhook: (state, data) => {
    const webhookDTO = {
      destination: data.Destination,
      name: data.Name,
    };

    state.webhook = webhookDTO;
  },
  updateWebhook: (state, { name, destination }) => {
    state.webhook.name = name;
    state.webhook.destination = destination;
  },
  deleteWebhook: (state) => {
    state.webhook.name = "";
    state.webhook.destination = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
