import { i18n } from "@/i18n";
import { SIDENAV_GROUP_BY } from "@/constants";
import { ARC_SERVICE } from "@/utils/services";

export const MSP_NAV_ITEMS = [
  {
    order: 3,
    label: i18n.global.t("ArcTitan"),
    groupBy: SIDENAV_GROUP_BY.BETA,
    icon: "cl-icon-tabler-archive",
    id: "/msp/arc_titan",
    dataTestId: "msp-arc-titan",
    solutionId: ARC_SERVICE,
    isExternal: true,
  },
];
export const CUSTOMER_NAV_ITEMS = [
  {
    order: 2,
    label: i18n.global.t("ArcTitan"),
    groupBy: SIDENAV_GROUP_BY.BETA,
    icon: "cl-icon-tabler-archive",
    id: "/customer/arc_titan",
    dataTestId: "customer-arc-titan",
    solutionId: ARC_SERVICE,
    isExternal: true,
  },
];

export default {};
