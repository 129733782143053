import { handleHttpError } from "@/utils/api-error-handling";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "../store/index";
import { useProgress, ProgressFinisher } from "@marcoschulte/vue3-progress";
import { App } from "vue";

axios.defaults.withCredentials = true;

export const $http = axios.create({ baseURL: import.meta.env.VITE_API_URL });

let refreshPromise: null | Promise<unknown> = null;
const clearPromise = () => (refreshPromise = null);

export async function refreshToken() {
  try {
    const response = await axios.post(
      import.meta.env.VITE_API_CLIENT + "/idp/oauth/token",
      {
        grant_type: "refresh_token",
        client_id: import.meta.env.VITE_API_CLIENT_ID,
        refresh_token: store.getters["authentication/token"]?.refresh_token,
      },
    );
    store.dispatch("authentication/refreshToken", response.data);
    return response.data.access_token;
  } catch (e) {
    localStorage.clear();
    window.location.assign("/");
  }
}

export function updateHttpConfig(token: string) {
  $http.defaults.headers["Authorization"] = `Bearer ${token}`;
}

export default {
  install(app: App) {
    app.config.globalProperties.$http = $http;

    const progresses: ProgressFinisher[] = [];

    /**
     * Attaches JWT as Bearer token in Authorization header to all subsequent requests.
     * @param {JWT} token JWT received from server
     */

    app.config.globalProperties.$http.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const token = store.getters["authentication/token"]?.access_token;
        progresses.push(useProgress().start());

        if (token && config.headers) {
          config.headers["Authorization"] = `Bearer ${token}`;

          return config;
        }

        return config;
      },
      (err: unknown) => {
        progresses.pop()?.finish();
        handleHttpError(err);

        return Promise.reject(err);
      },
    );

    app.config.globalProperties.$http.interceptors.response.use(
      (response: AxiosResponse) => {
        progresses.pop()?.finish();

        return response;
      },
      async (error: AxiosError) => {
        if (
          store.getters["authentication/authUser"]?.expired <
          new Date().getTime() / 1000
        ) {
          const config = error.config;
          if (config && !config._retry) {
            config._retry = true;
            if (!refreshPromise) {
              refreshPromise = refreshToken().finally(clearPromise);
            }
            const token = await refreshPromise;

            config.headers["Authorization"] = `Bearer ${token}`;

            return $http(config);
          }
        }

        progresses.pop()?.finish();
        if (!error?.config?._hasCustomErrorHandling) {
          handleHttpError(error);
        }

        return Promise.reject(error);
      },
    );
  },
};
