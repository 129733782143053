import { i18n } from "@/i18n";
import { isAxiosError, AxiosError } from "axios";
import { store } from "@/store";

const mapStatusCodeToMessage = (statusCode: number) => {
  const clientError = statusCode >= 400 && statusCode <= 499;

  if (clientError) {
    switch (statusCode) {
      case 400:
        return i18n.global.t("Sorry, this request is invalid.");
      case 401:
        return i18n.global.t("Sorry, this request is not authorized");
      case 403:
        return i18n.global.t(
          "Sorry, you don't have permission to access this resource.",
        );
      case 404:
        return i18n.global.t(
          "Sorry, we couldn't find what you are looking for.",
        );
      case 405:
        return i18n.global.t("Sorry, this action is not supported.");
      case 408:
        return i18n.global.t(
          "Sorry, your request timed out - please check your connection.",
        );
      case 409:
        return i18n.global.t(
          "Sorry, we can't complete this action. Please check the details and retry.",
        );
      case 422:
        return i18n.global.t(
          "Sorry, we can't complete this action. Please check the details and retry.",
        );
      case 429:
        return i18n.global.t(
          "Sorry, too many requests were sent in a short period.",
        );
      default:
        return i18n.global.t("An unexpected error occurred.");
    }
  } else {
    return i18n.global.t(
      "Something went wrong on our side. Please try again later.",
    );
  }
};

const handleUnexpectedError = () => {
  // TODO: Frontend Monitoring https://gitlab.titanhq.com/titanhq/dev/scrum/team-hermes/-/issues/152
};
const handleAxiosError = (err: AxiosError) => {
  const statusCode = err.response?.status;
  const url = err.request?.responseURL;

  if (!statusCode) {
    return handleUnexpectedError();
  }

  // This is a temporary workaround while we deploy ArcTitan to production
  // TODO: Remove as soon Arctitan is deployed officially
  if (statusCode === 422 && url.includes("/customers/tag")) {
    return;
  }
  if (statusCode === 404 && url.includes("/sso/saml/enabled")) {
    return;
  }

  if (statusCode === 401) {
    return;
  }

  store.dispatch(
    "toast/displayToast",
    {
      title: i18n.global.t("Error"),
      message: mapStatusCodeToMessage(statusCode),
      variant: "danger",
      duration: 5000,
    },
    { root: true },
  );
};

export const handleHttpError = (err: unknown) => {
  isAxiosError(err) ? handleAxiosError(err) : handleUnexpectedError();
};
