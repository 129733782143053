// Based on .env VITE_ENV_MODE
export const PRODUCTION_ENVS = ["staging", "platform"];
export const TEST_STAGING_ENV_MODE = "test";
export const TEST_DEV_ENV_MODE = "test-dev";
export const DEV_ENV_MODE = "development";

export type FlagName =
  | "FEATURE_TOUR"
  | "PHISHING_SIMULATIONS_CUSTOMER"
  | "SAT_MSP"
  | "SAT_CUSTOMER"
  | "GRAYMAIL"
  | "INCIDENTS_V3"
  | "OVERVIEW_CARDS";

type Config = {
  [K in FlagName]: {
    dev: boolean;
    testDev: boolean;
    testStaging: boolean;
    prod: boolean;
  };
};

export const FLAGS_CONFIG: Config = {
  FEATURE_TOUR: {
    dev: true,
    testDev: true,
    testStaging: true,
    prod: true,
  },
  PHISHING_SIMULATIONS_CUSTOMER: {
    dev: true,
    testDev: true,
    testStaging: true,
    prod: true,
  },
  SAT_MSP: {
    dev: true,
    testDev: true,
    testStaging: true,
    prod: true,
  },
  SAT_CUSTOMER: {
    dev: true,
    testDev: true,
    testStaging: true,
    prod: true,
  },
  // https://gitlab.titanhq.com/titanhq/dev/scrum/team-hermes/-/issues/730
  GRAYMAIL: {
    dev: true,
    testDev: true,
    testStaging: true,
    prod: true,
  },
  // https://gitlab.titanhq.com/groups/titanhq/dev/scrum/-/epics/58
  INCIDENTS_V3: {
    dev: false,
    testDev: true,
    testStaging: false,
    prod: false,
  },
  // https://gitlab.titanhq.com/titanhq/dev/scrum/team-sherman/-/issues/188
  OVERVIEW_CARDS: {
    dev: true,
    testDev: true,
    testStaging: true,
    prod: true,
  },
};
