import { i18n } from "@/i18n";
import { SIDENAV_GROUP_BY } from "@/constants";

export const MSP_NAV_ITEMS = [
  {
    order: 5,
    groupBy: SIDENAV_GROUP_BY.GAMMA,
    label: i18n.global.t("License Usage"),
    icon: "cl-icon-tabler-icon-recharging",
    id: "/msp/license_usage",
    dataTestId: "msp-license-usage",
  },
];

export const CUSTOMER_NAV_ITEMS = [
  {
    order: 5,
    groupBy: SIDENAV_GROUP_BY.GAMMA,
    label: i18n.global.t("License Usage"),
    icon: "cl-icon-tabler-icon-recharging",
    id: "/customer/license_usage",
    dataTestId: "customer-license-usage",
  },
];

export default {};
