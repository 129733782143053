<template>
  <div
    class="tw-flex tw-w-full tw-items-start tw-justify-between tw-gap-3 tw-rounded-sm tw-border-2 tw-border-danger-500 tw-bg-danger-100 tw-p-3 tw-shadow"
  >
    <div class="tw-relative">
      <cl-p class="tw-mb-0">
        <span class="tw-inline-flex tw-items-center">
          <cl-icon-tabler-alert-triangle
            :height="40"
            :width="40"
            class="tw-mr-4 tw-inline-flex"
          />
          <div class="tw-flex tw-flex-col">
            <b>Account not protected</b>
            <p class="tw-text-sm">Connect your account to Microsoft 365</p>
          </div>
        </span>
      </cl-p>
    </div>
    <div class="tw-relative tw-self-center">
      <router-link
        v-slot="{ href, navigate }"
        :to="{ name: 'customer-settings-connections' }"
      >
        <a :href="href" class="" @click="navigate">{{
          $t("Add Connection")
        }}</a>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("history", ["getMailFlow"]),
  },
  watch: {
    getMailFlow: {
      handler() {
        return this.getMailFlow;
      },
      deep: true,
    },
  },
};
</script>
