import { $http } from "@/plugins/api";
import { $userPrefs } from "@/plugins/userPrefs";
import { encode as base64encode } from "base64-arraybuffer";

import { ACCOUNT_TYPE } from "@/constants";

async function generateCodeChallenge(codeVerifier) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  const base64Digest = base64encode(digest);
  return base64Digest.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

function randomstring(size) {
  return [...Array(size)]
    .map(() => (~~(Math.random() * 36)).toString(36))
    .join("");
}

const getAuthDefaultState = () => {
  return {
    selectedAccount: null,
    selectedMsp: null,
    selectedCustomer: null,
    selectedDomain: null,
    selectedUser: null,
    token: null,
    role: null,
    organisations: null,
    authUser: null,
    mspId: null,
    backupCodes: null,
    permissions: null,
    callbackID: null,
    ssoEnabled: false,
    ssoLogin: false,
    ssoLoginError: null,
    ssoLoginSuccess: false,
    ssoLoginSuccessMessage: null,
    ssoLoginErrorMessage: null,
    ssoLoginErrorTitle: null,
    ssoLoginSuccessTitle: null,
  };
};
const state = getAuthDefaultState();

const getters = {
  selectedAccount: (state) => state.selectedAccount,
  selectedMsp: (state) => state.selectedMsp,
  selectedCustomer: (state) => state.selectedCustomer,
  selectedDomain: (state) => state.selectedDomain,
  selectedUser: (state) => state.selectedUser,
  token: (state) => state.token,
  accounts: (state) => state.accounts,
  role: (state) => state.role,
  authUser: (state) => state.authUser,
  mspId: (state) => state.mspId,
  backupCodes: (state) => state.backupCodes,
  permissions: (state) => state.permissions,
  callbackID: (state) => state.callbackID,
  ssoEnabled: (state) => state.ssoEnabled,
  ssoLogin: (state) => state.ssoLogin,
  ssoLoginError: (state) => state.ssoLoginError,
  ssoLoginSuccess: (state) => state.ssoLoginSuccess,
  ssoLoginSuccessMessage: (state) => state.ssoLoginSuccessMessage,
  ssoLoginErrorMessage: (state) => state.ssoLoginErrorMessage,
  ssoLoginErrorTitle: (state) => state.ssoLoginErrorTitle,
  ssoLoginSuccessTitle: (state) => state.ssoLoginSuccessTitle,
};

const actions = {
  /**
   * Either the user id or the email address must be provided.
   * If the oauth keys have already been created, they are returned without any further changes.
   * @values user: { id: Number, email: String }
   * @returns userObject
   */
  async ssoEnabled({ commit }, email) {
    const ssoEnabled = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/sso/saml/enabled/${email}`,
    );
    commit("setSsoEnabled", ssoEnabled.data);
    return ssoEnabled;
  },
  async ssoLogin({ commit }, email) {
    const ssoLogin = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/sso/saml/login/${email}`,
    );
    commit("isSSOLogin", ssoLogin.data);
    return ssoLogin;
  },
  refreshToken({ commit }, accessToken) {
    const tokens = accessToken.access_token.split(".");
    const payload = atob(tokens[1]);
    const jwtPayload = JSON.parse(payload);
    const authuser = jwtPayload["user"] || {};

    commit("setToken", accessToken);

    commit("setAuthUser", {
      uuid: authuser.uuid,
      id: jwtPayload["sub"],
      accountname: authuser.name || "",
      email: authuser.email || "",
      twoFactorAuthEnabled: authuser.enabled_2fa,
      expired: jwtPayload["exp"],
    });
    $userPrefs.updateUserPrefs({
      token: accessToken.access_token,
    });
  },

  async logUserOut() {
    const response = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/idp/logout`,
    );

    return response;
  },
  clearAuthCache({ commit }) {
    commit("resetState");
  },
  async setToken({ commit }, token) {
    commit("setToken", token);
  },

  async setCustomer({ commit }, customer) {
    commit("setSelectedCustomer", customer);
  },

  async setDomain({ commit }, domain) {
    commit("setSelectedDomain", domain);
  },

  async setUser({ commit }, selectedUser) {
    commit("setUser", selectedUser);
  },

  async setMspUser({ commit }, selectedMsp) {
    commit("setMspUser", selectedMsp);
  },

  async setMspId({ commit }, mspId) {
    commit("setMspId", mspId);
  },

  async setRole({ commit }, role) {
    commit("setRole", role);
  },

  async enable2fa({ commit }, code) {
    const response = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/idp/users/${code.id}/2fa`,
      {
        secret: code.secret,
        code: code.code,
      },
      { _hasCustomErrorHandling: true },
    );
    const is2fa = response.data.backup_codes ? true : false;
    $userPrefs.updateUserPrefs({
      twoFactorAuthEnabled: is2fa,
    });

    commit("set2Fa", is2fa);
    commit("setBackupCodes", response.data.backup_codes);
  },

  async disable2fa({ commit }, uuid) {
    const response = await $http.delete(
      `${import.meta.env.VITE_API_CLIENT}/idp/users/${uuid}/2fa`,
    );
    const isDisabled = response.data.uuid ? false : true;
    $userPrefs.updateUserPrefs({
      twoFactorAuthEnabled: isDisabled,
    });

    commit("set2Fa", isDisabled);
    commit("setBackupCodes", []);
  },

  // REFACTOR SSOTOKEN AND REQUEST TOKEN ...
  // both kinda do the same, think theres alot of junk in there
  async ssoToken({ commit }, accessToken) {
    const tokens = accessToken.access_token.split(".");
    const payload = atob(tokens[1]);
    const jwtPayload = JSON.parse(payload);
    const authuser = jwtPayload["user"] || {};
    const organisation = jwtPayload["organisation"] || {};

    commit("setToken", accessToken);

    commit("setAuthUser", {
      uuid: authuser.uuid,
      id: jwtPayload["sub"],
      accountname: authuser.name || "",
      email: authuser.email || "",
      twoFactorAuthEnabled: authuser.enabled_2fa,
      expired: jwtPayload["exp"],
    });
    $userPrefs.updateUserPrefs({
      token: accessToken.access_token,
    });

    commit("setOrganisations", organisation);

    commit("setSelectedAccount", organisation);

    if (organisation.type_name === ACCOUNT_TYPE.MSP) {
      commit("setMspUser", {
        id: authuser.uuid,
        name: authuser.name,
        email: authuser.email || "",
      });
    }

    if (organisation.type_name === ACCOUNT_TYPE.DOMAIN) {
      const domain = {
        id: authuser.uuid,
        name: authuser.name,
        email: authuser.email || "",
      };
      commit("setSelectedDomain", domain);
    }

    if (organisation.type_name === ACCOUNT_TYPE.CUSTOMER) {
      const customer = {
        uuid: organisation.uuid,
        name: organisation.name,
        allCustomers: false,
      };
      commit("setSelectedCustomer", customer);
    }

    if (organisation.type_name === ACCOUNT_TYPE.USER) {
      const userInd = {
        id: authuser.uuid,
        name: authuser.name,
        email: authuser.email || "",
      };
      commit("setUser", userInd);
    }
  },
  async requestToken({ commit }, user) {
    const code_verifier = randomstring(128);
    const code_challenge = await generateCodeChallenge(code_verifier);
    const client_id = import.meta.env.VITE_API_CLIENT_ID;

    let params = {
      client_id,
      code_verifier,
      code_challenge,
      redirect_uri: import.meta.env.VITE_API_CLIENT + "idp/oauth/callback",
    };

    if (user["code"]) {
      params = { ...params, code: user["code"] };
    }

    const auth = {
      username: user["username"],
      password: user["password"],
    };

    const response = await $http.get(
      `${import.meta.env.VITE_API_CLIENT}/idp/oauth/authorize`,
      {
        params: params,
        auth,
      },
    );

    commit("setToken", response.data);

    const tokens = response.data.access_token.split(".");
    const payload = atob(tokens[1]);
    const jwtPayload = JSON.parse(payload);
    const organisation = jwtPayload["organisation"] || {};
    const authuser = jwtPayload["user"] || {};
    // const permissions = jwtPayload["permissions"] || [];

    commit("setOrganisations", organisation);

    commit("setAuthUser", {
      uuid: authuser.uuid,
      id: jwtPayload["sub"],
      accountname: authuser.name || "",
      email: authuser.email || "",
      twoFactorAuthEnabled: authuser.enabled_2fa,
      expired: jwtPayload["exp"],
    });

    commit("setSelectedAccount", organisation);
    // commit("setPermissions", permissions);

    if (organisation.type_name === ACCOUNT_TYPE.MSP) {
      // commit("setMspId", jwtPayload["msp_id"]);
      commit("setMspUser", {
        id: authuser.uuid,
        name: authuser.name,
        email: authuser.email || "",
      });
    }

    if (organisation.type_name === ACCOUNT_TYPE.DOMAIN) {
      const domain = {
        id: authuser.uuid,
        name: authuser.name,
        email: authuser.email || "",
      };
      commit("setSelectedDomain", domain);
    }

    if (organisation.type_name === ACCOUNT_TYPE.CUSTOMER) {
      const customer = {
        uuid: organisation.uuid,
        name: organisation.name,
        allCustomers: false,
      };
      commit("setSelectedCustomer", customer);
    }

    if (organisation.type_name === ACCOUNT_TYPE.USER) {
      const userInd = {
        id: authuser.uuid,
        name: authuser.name,
        email: authuser.email || "",
      };
      commit("setUser", userInd);
    }

    return response;
  },

  async forgotPassword(_context, email) {
    const response = await $http.post(
      `${import.meta.env.VITE_API_CLIENT}/idp/password/forgot`,
      email,
    );
    return response.data;
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getAuthDefaultState());
  },
  setSsoEnabled(state, enabled) {
    state.ssoEnabled = enabled;
  },
  setUser: (state, selectedUser) =>
    (state.selectedUser = {
      accountname: selectedUser.name,
      name: selectedUser.name,
      email: selectedUser.email,
      id: selectedUser.id,
      allCustomers: selectedUser.allCustomers,
    }),
  setToken(state, token) {
    state.token = token;
    // $http.setAuthorisationToken(token.access_token);
  },
  isSSOLogin(state, login) {
    state.ssoLogin = login;
  },
  setCallbackID: (state, id) => (state.callbackID = id),
  setOrganisations: (state, organisations) =>
    (state.organisations = organisations),
  setRole: (state, role) => (state.role = role),
  set2Fa: (state, enabled) => (state.authUser.twoFactorAuthEnabled = enabled),
  setBackupCodes: (state, codes) => (state.backupCodes = codes),
  setMspId: (state, mspId) => (state.mspId = mspId),
  setAuthUser: (state, authUser) => (state.authUser = authUser),
  setSelectedCustomer: (state, customer) => (state.selectedCustomer = customer),
  setSelectedDomain: (state, domain) => (state.selectedDomain = domain),
  setMspUser: (state, selectedMsp) => (state.selectedMsp = selectedMsp),
  setSelectedAccount: (state, selectedAccount) =>
    (state.selectedAccount = selectedAccount),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
