<template>
  <Teleport to="body">
    <cl-modal
      :test-id="'modal-add-administrator'"
      :header-label="$t('Add an Administrator')"
      :visible="showModal"
      @on-close="removeModal"
    >
      <form autocomplete="off" @submit.prevent="submit">
        <div class="tw-mx-3 tw-mb-3 tw-grid tw-grid-cols-2 tw-gap-5">
          <div class="w-full w-2/4 px-2 mb-2 md:mb-0 tw-col-span-1">
            <cl-form-group>
              <cl-form-label :label-for="$t('MSP Name')">
                {{ $t("Name") }}
              </cl-form-label>
              <cl-form-input
                v-model:value="v$.createAdminForm.name.$model"
                :name="'name'"
                :type="'text'"
                :placeholder="'Full Name'"
                :data-test-id="`admin-full-name`"
                :state="
                  !v$.createAdminForm.name.$dirty
                    ? null
                    : !v$.createAdminForm.name.$error
                "
              />
            </cl-form-group>
            <span
              v-if="v$.createAdminForm.name.$error"
              class="tw-text-danger-500"
              >{{ $t("Required field.") }}</span
            >
          </div>
          <div class="w-full md:w-1/2 px-2">
            <cl-form-group>
              <cl-form-label :label-for="$t('Email')">
                {{ $t("Email") }}
              </cl-form-label>
              <cl-form-input
                v-model:value="createAdminForm.email"
                :name="'email'"
                :type="'text'"
                :data-test-id="`admin-email`"
                :placeholder="$t('Email')"
                :state="
                  !v$.createAdminForm.email.$dirty
                    ? null
                    : !v$.createAdminForm.email.$error
                "
              />
            </cl-form-group>
            <span
              v-if="v$.createAdminForm.email.$error"
              class="tw-text-danger-500"
              >{{ $t("Please enter a valid email") }}</span
            >
          </div>
        </div>
        <div class="tw-mx-3 tw-mb-3 tw-grid tw-grid-cols-2 tw-gap-5">
          <div class="w-full w-2/4 px-2 mb-2 md:mb-0 tw-col-span-1">
            <cl-form-group>
              <cl-form-label :label-for="$t('Password')">
                {{ $t("Password") }}
              </cl-form-label>
              <cl-form-input
                v-model:value="v$.createAdminForm.password.$model"
                :name="'password'"
                :type="'password'"
                :placeholder="'Password'"
                :data-test-id="`admin-password`"
                :state="
                  !v$.createAdminForm.password.$dirty
                    ? null
                    : !v$.createAdminForm.password.$error
                "
              />
            </cl-form-group>
            <span
              v-if="v$.createAdminForm.password.$error"
              class="tw-text-danger-500"
              >{{
                $t(
                  "A password must contain at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character and have a length of at least 12",
                )
              }}</span
            >
          </div>
          <div class="w-full md:w-1/2 px-2">
            <cl-form-group>
              <cl-form-label :label-for="$t('Confirm Password')">
                {{ $t("Confirm Password") }}
              </cl-form-label>
              <cl-form-input
                v-model:value="v$.createAdminForm.confirm_password.$model"
                :name="'password'"
                :type="'password'"
                :data-test-id="`admin-confirm-password`"
                :placeholder="$t('Confirm Password')"
                :state="
                  !v$.createAdminForm.confirm_password.$dirty
                    ? null
                    : !v$.createAdminForm.confirm_password.$error
                "
              />
            </cl-form-group>
            <span
              v-if="v$.createAdminForm.confirm_password.$error"
              class="tw-text-danger-500"
              >{{
                $t("The Password and the Confirm Password fields should match")
              }}</span
            >
          </div>
        </div>
      </form>
      <template #[`footer.decline`]>
        <cl-button
          data-test-id="add-administrator-cancel-btn"
          :disabled="fetching"
          @on-click="removeModal"
        >
          <span>{{ $t("Cancel") }}</span>
        </cl-button>
      </template>
      <template #[`footer.accept`]>
        <cl-button v-if="fetching" :variant="'secondary'" disabled>
          <cl-spinner :size="'small'" class="mr-2" />
          <span>Saving...</span>
        </cl-button>
        <cl-button
          v-else
          data-test-id="add-administrator-add-btn"
          :variant="'secondary'"
          @on-click="submit"
        >
          <span>{{ $t("Add Administrator") }}</span>
        </cl-button>
      </template>
    </cl-modal>
  </Teleport>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";

import { mapActions, mapGetters } from "vuex";

import { STRONG_PASSWORD } from "@/constants";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      fetching: false,
      errors: null,
      createAdminForm: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  validations() {
    return {
      createAdminForm: {
        name: { required },
        email: { required, email },
        password: {
          required,
          strongPassword: (password) => STRONG_PASSWORD.test(password),
        },
        confirm_password: {
          required,
          sameAsPassword: sameAs(this.createAdminForm.password),
        },
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["showModal", "modalData"]),
  },
  methods: {
    ...mapActions("modal", ["closeModal", "openModal", "clearModalCache"]),
    ...mapActions("users", ["createUser"]),
    ...mapActions("toast", ["displayToast"]),
    removeModal() {
      for (const key in this.createAdminForm) {
        this.createAdminForm[key] = "";
      }
      this.v$.createAdminForm.$reset();

      this.clearModalCache();
      this.closeModal();
    },
    async submit() {
      const isValid = await this.v$.createAdminForm.$validate();

      if (!isValid) {
        return;
      }

      try {
        this.fetching = true;

        await this.createUser({
          uuid: this.modalData.id,
          formData: this.createAdminForm,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("User Admin has been created"),
          duration: 2000,
          variant: "success",
        });

        this.removeModal();
      } catch (err) {
        // Handled in axios interceptor
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
