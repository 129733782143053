import { $http } from "@/plugins/api";

import type { Commit } from "vuex";
import type {
  Campaign,
  SATCustomerCampaignSummary,
  SATCampaignUsers,
} from "./types";
import type { Collection, Params } from "@/types/api";

interface State {
  campaigns: Collection<Campaign>;
  satCampaignUsers: Collection<SATCampaignUsers>;
  customerCampaignSummary: SATCustomerCampaignSummary | null;
}

const state: State = {
  campaigns: {
    current_page: 1,
    page_size: 5,
    results: [],
    total: 0,
  },
  satCampaignUsers: {
    current_page: 1,
    page_size: 5,
    results: [],
    total: 0,
  },
  customerCampaignSummary: null,
};

export const getters = {
  campaigns: (state: State) => state.campaigns,
};

export const actions = {
  async getCampaigns(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      params,
    }: {
      globalOrganisationID: string | null;
      params: Params;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const reqParams: Params = {};

    for (const [key, value] of Object.entries(params)) {
      reqParams[key] = value.toString().toLocaleLowerCase();
    }

    const searchParams = new URLSearchParams(reqParams);

    const res = await $http.get(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/sat/${globalOrganisationID}/campaigns?${searchParams.toString()}`,
    );

    commit("setCampaigns", res.data);
  },
  async getCustomerCampaignSummary(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      campaignUuid,
      customerUuid,
    }: {
      globalOrganisationID: string | null;
      campaignUuid: string;
      customerUuid: string;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const res = await $http.get(
      `${
        import.meta.env.VITE_FAUXPI_CLIENT
      }/cams/manager/sat/${globalOrganisationID}/campaigns/${campaignUuid}/${customerUuid}`,
    );

    commit("setCustomerCampaignSummary", res.data);
  },
  async getCampaignUsers(
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      campaignUuid,
      customerUuid,
      params,
    }: {
      globalOrganisationID: string;
      campaignUuid: string;
      customerUuid: string;
      params: Params;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    const reqParams: Params = {};

    for (const [key, value] of Object.entries(params)) {
      reqParams[key] = value.toString().toLocaleLowerCase();
    }

    const searchParams = new URLSearchParams(reqParams);

    const res = await $http.get(
      `${
        import.meta.env.VITE_FAUXPI_CLIENT
      }/cams/manager/sat/${globalOrganisationID}/campaigns/${campaignUuid}/${customerUuid}?${searchParams.toString()}`,
    );

    commit("setCampaignUsers", res.data);
  },

  async createCampaign(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      campaign,
    }: {
      globalOrganisationID: string | null;
      campaign: Partial<Campaign>;
    },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }

    await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/sat/${globalOrganisationID}/campaigns`,
      campaign,
    );
  },
  async batchDelete(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    {
      globalOrganisationID,
      ids,
    }: { globalOrganisationID: string | null; ids: string[] },
  ) {
    if (globalOrganisationID === null) {
      throw new Error("Organisation ID is not provided");
    }
    await $http.post(
      `${
        import.meta.env.VITE_API_CLIENT
      }/cams/manager/sat/${globalOrganisationID}/campaigns/delete`,
      ids,
    );
  },
};

const mutations = {
  setCampaigns: (state: State, data: Collection<Campaign>) =>
    (state.campaigns = data),
  setCampaignUsers: (state: State, data: Collection<SATCampaignUsers>) =>
    (state.satCampaignUsers = data),
  setCustomerCampaignSummary: (
    state: State,
    data: SATCustomerCampaignSummary,
  ) => (state.customerCampaignSummary = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
