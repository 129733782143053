import { USER_ROLES } from "@/constants";

const customerRoutes = [
  {
    path: "",
    redirect: "/customer/phish_titan",
    meta: {
      authorize: [USER_ROLES.SYSTEM, USER_ROLES.MSP, USER_ROLES.CUSTOMER],
    },
  },
];

export default customerRoutes;
