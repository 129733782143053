export const mspRoutes = {
  path: "/msp",
  component: () => import("@/layouts/Msp/MspLayout.vue"),
  children: [
    {
      path: "license_usage",
      name: "mspUsageRoute",
      component: () => import("@/modules/Usage/views/Msp"),
      redirect: {
        name: "mspUsageOverview",
      },
      children: [
        {
          path: "",
          name: "mspUsageOverview",
          component: () => import("@/modules/Usage/views/Msp/Overview"),
        },
      ],
    },
  ],
};

export default {};
