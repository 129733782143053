const profileRoutes = [
  {
    path: "",
    exact: false,
    redirect: {
      name: "profileSecurity",
    },
  },
  {
    path: "security",
    name: "profileSecurity",
    exact: false,
    component: () => import("@/views/Profile/Security"),
  },
];

export default profileRoutes;
