import { mspRoutes, customerRoutes } from "./routes";
import modules from "./store";

import { NAV_ITEMS_MODE } from "@/constants";
import { MSP_NAV_ITEMS, CUSTOMER_NAV_ITEMS } from "./constants";

const ArcTitanModule = ({ router, registerNavItems, store }) => {
  // Routes
  router.addRoute(mspRoutes);
  router.addRoute(customerRoutes);

  // Stores
  store.registerModule("arc_titan", modules.arcTitan);

  // Nav Items
  registerNavItems(NAV_ITEMS_MODE.MSP, MSP_NAV_ITEMS);
  registerNavItems(NAV_ITEMS_MODE.CUSTOMER_ADMIN, CUSTOMER_NAV_ITEMS);
};

export { ArcTitanModule };

export default {};
