import { createApp } from "vue";
import { Vue3ProgressPlugin } from "@marcoschulte/vue3-progress";
import VueDOMPurifyHTML from "vue-dompurify-html";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css"; // optional for styling
import * as Sentry from "@sentry/vue";

import App from "@/App.vue";

import { router } from "@/router";
import { store } from "@/store";
import { i18n } from "@/i18n";
import globalDerectives from "@/derectives";

import api from "@/plugins/api";
import getAccountShortName from "@/plugins/getAccountShortName";
import userPrefs from "@/plugins/userPrefs";
import jsonToCsv from "@/plugins/jsonToCsv";
import imageUrl from "@/plugins/imageUrl";

import { usePrimaryNavItems } from "@/composables";

import {
  ClDataTable,
  ClFormLabel,
  ClFormInput,
  ClFormGroup,
  ClDataTableDeleteAction,
  ClDataTableEditAction,
  ClDataTableViewAction,
  ClButton,
  ClHeading,
  ClP,
  ClHr,
  ClTooltip,
  ClPill,
  ClCardTrend,
  ClModal,
  ClSpinner,
  ClSidebar,
  ClSidebarNav,
  ClSidebarNavItemParent,
  ClSidebarNavItemChild,
  ClSidebarDropdown,
  ClSidebarDropdownSearch,
  ClSidebarDropdownMenuItem,
  ClDropdown,
  ClDropdownItem,
  ClTabs,
  ClTabsItem,
  ClBadge,
  ClBadgeLabel,
  ClEmailAttachment,
  ClSkeleton,
  ClSkeletonRow,
  ClFooter,
  ClBreadcrumbs,
  ClHeaderDropdown,
  ClHeaderDropdownProduct,
  ClHeaderDropdownLink,
  ClToast,
  ClFormRadioGroup,
  ClFormTextarea,
  ClFormSelect,
  ClActiveFilter,
  ClStepper,
  ClToggle,
  ClTableWrapper,
  ClTable,
  ClThead,
  ClTbody,
  ClTr,
  ClTh,
  ClTd,
  ClTrSkeleton,
  ClThSorting,
  ClTablePageSize,
  ClTableBasicPagination,
  ClTablePaginationState,
  ClInputCheckbox,
  ClInputSearch,
  ClInputRadio,
  ClTableColumnVisibilityToggle,
  ClAccordion,
  ClProgressBar,
  // Icons
  ClIconArrowUpRight,
  ClIconPencilSquare,
  ClIconColumnsGap,
  ClIconTablerDashboard,
  ClIconTablerFlag3,
  ClIconTablerIconBulb,
  ClIconTablerIconChartBar,
  ClIconTablerIconRecharging,
  ClIconTablerSettings,
  ClTablerIconUsers,
  ClTablerIconBuildingSkyscraper,
  ClIconTablerBriefcase,
  ClIconTablerWordUp,
  ClIconTablerChevronDown,
  ClIconTablerChevronRight,
  ClIconTablerShieldCheck,
  ClIconTablerEditCircle,
  ClIconTablerIconEye,
  ClIconEnvelopeAt,
  ClIconEnvelopeLetter,
  ClIconPaperBoard,
  ClIconTablerCopy,
  ClIconTablerInfoFilled,
  ClIconTablerLogout,
  ClIconTablerLifebuoy,
  ClIconTablerAlertTriangle,
  ClIconTablerFishHook,
  ClIconTablerInfoCircle,
  ClIconTablerMailCheck,
  ClIconTablerMail,
  ClIconTablerArchive,
  ClIconTablerSchool,
  ClIconTableCheck,
  ClIconTablerWorldCheck,
  ClIconTablerTable,
  ClIconTablerX,
  ClIconTablerSearch,
  ClIconTablerExternalLink,
  ClIconTablerFileTypePdf,
  ClIconTablerFile,
  ClIconTablerDownload,
  ClIconTablerSparklesHorizontal,
  ClIconTablerCircleCheckFilled,
  ClIconTablerAlertCircleFilled,
} from "@titanhq/frontend-component-library";

// Modules
import {
  PhishTitanModule,
  LicenceUsageModule,
  SettingsModule,
  PhishingSimulationsModule,
  ArcTitanModule,
  SecurityAwarnessTrainingModule,
} from "@/modules/index";

import "@/assets/main.css";
import "@marcoschulte/vue3-progress/dist/index.css";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://192076b14b796bd099207bfbfc47ab17@o4507293832511488.ingest.us.sentry.io/4507538100715520",
  environment: "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { registerNavItems } = usePrimaryNavItems();

function registerModule(module) {
  module({ app, store, router, registerNavItems });
}

registerModule(LicenceUsageModule);
registerModule(SettingsModule);
registerModule(PhishingSimulationsModule);
registerModule(PhishTitanModule);
registerModule(ArcTitanModule);
registerModule(SecurityAwarnessTrainingModule);

app.use(i18n);
app.use(store);
app.use(router);
app.use(VueDOMPurifyHTML);
app.use(imageUrl);
app.use(userPrefs);
app.use(getAccountShortName);
app.use(api);
app.use(jsonToCsv);
app.use(Vue3ProgressPlugin);

app.use(globalDerectives);

app.use(
  VueTippy,
  // optional
  {
    directive: "tippy", // => v-tippy
    component: "tippy", // => <tippy/>
    componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
    defaultProps: {
      placement: "auto-end",
      allowHTML: true,
    }, // => Global default options * see all props
  },
);

app.component("ClDataTable", ClDataTable);
app.component("ClFormLabel", ClFormLabel);
app.component("ClFormTextarea", ClFormTextarea);
app.component("ClFormInput", ClFormInput);
app.component("ClFormGroup", ClFormGroup);
app.component("ClDataTableEditAction", ClDataTableEditAction);
app.component("ClDataTableDeleteAction", ClDataTableDeleteAction);
app.component("ClDataTableViewAction", ClDataTableViewAction);
app.component("ClButton", ClButton);
app.component("ClHeading", ClHeading);
app.component("ClP", ClP);
app.component("ClHr", ClHr);
app.component("ClTooltip", ClTooltip);
app.component("ClPill", ClPill);
app.component("ClCardTrend", ClCardTrend);
app.component("ClModal", ClModal);
app.component("ClSpinner", ClSpinner);
app.component("ClSidebar", ClSidebar);
app.component("ClSidebarNav", ClSidebarNav);
app.component("ClSidebarNavItemParent", ClSidebarNavItemParent);
app.component("ClSidebarNavItemChild", ClSidebarNavItemChild);
app.component("ClSidebarDropdown", ClSidebarDropdown);
app.component("ClSidebarDropdownSearch", ClSidebarDropdownSearch);
app.component("ClSidebarDropdownMenuItem", ClSidebarDropdownMenuItem);
app.component("ClDropdown", ClDropdown);
app.component("ClDropdownItem", ClDropdownItem);
app.component("ClTabs", ClTabs);
app.component("ClTabsItem", ClTabsItem);
app.component("ClBadge", ClBadge);
app.component("ClBadgeLabel", ClBadgeLabel);
app.component("ClEmailAttachment", ClEmailAttachment);
app.component("ClSkeleton", ClSkeleton);
app.component("ClSkeletonRow", ClSkeletonRow);
app.component("ClFooter", ClFooter);
app.component("ClBreadcrumbs", ClBreadcrumbs);
app.component("ClHeaderDropdown", ClHeaderDropdown);
app.component("ClHeaderDropdownProduct", ClHeaderDropdownProduct);
app.component("ClHeaderDropdownLink", ClHeaderDropdownLink);
app.component("ClToast", ClToast);
app.component("ClFormRadioGroup", ClFormRadioGroup);
app.component("ClFormSelect", ClFormSelect);
app.component("ClActiveFilter", ClActiveFilter);
app.component("ClStepper", ClStepper);
app.component("ClToggle", ClToggle);
app.component("ClInputCheckbox", ClInputCheckbox);
app.component("ClInputSearch", ClInputSearch);
app.component("ClTableWrapper", ClTableWrapper);
app.component("ClTable", ClTable);
app.component("ClThead", ClThead);
app.component("ClTbody", ClTbody);
app.component("ClTr", ClTr);
app.component("ClTh", ClTh);
app.component("ClTd", ClTd);
app.component("ClTrSkeleton", ClTrSkeleton);
app.component("ClThSorting", ClThSorting);
app.component("ClTablePageSize", ClTablePageSize);
app.component("ClTableBasicPagination", ClTableBasicPagination);
app.component("ClTablePaginationState", ClTablePaginationState);
app.component("ClInputRadio", ClInputRadio);
app.component("ClTableColumnVisibilityToggle", ClTableColumnVisibilityToggle);
app.component("ClAccordion", ClAccordion);
app.component("ClProgressBar", ClProgressBar);

// Icons
app.component("ClIconArrowUpRight", ClIconArrowUpRight);
app.component("ClIconPencilSquare", ClIconPencilSquare);
app.component("ClIconColumnsGap", ClIconColumnsGap);
app.component("ClIconTablerDashboard", ClIconTablerDashboard);
app.component("ClIconTablerFlag3", ClIconTablerFlag3);
app.component("ClIconTablerIconBulb", ClIconTablerIconBulb);
app.component("ClIconTablerIconChartBar", ClIconTablerIconChartBar);
app.component("ClIconTablerIconRecharging", ClIconTablerIconRecharging);
app.component("ClIconTablerSettings", ClIconTablerSettings);
app.component("ClIconTablerBriefcase", ClIconTablerBriefcase);

app.component("ClTablerIconUsers", ClTablerIconUsers);
app.component(
  "ClIconTablerIconBuildingSkyscraper",
  ClTablerIconBuildingSkyscraper,
);
app.component("ClIconTablerIconWorldUp", ClIconTablerWordUp);
app.component("ClIconTablerChevronRight", ClIconTablerChevronRight);

app.component("ClIconTablerIconShieldCheck", ClIconTablerShieldCheck);
app.component("ClIconTablerIconEditCircle", ClIconTablerEditCircle);
app.component("ClIconTablerIconEye", ClIconTablerIconEye);
app.component("ClIconEnvelopeAt", ClIconEnvelopeAt);
app.component("ClIconEnvelopeLetter", ClIconEnvelopeLetter);
app.component("ClIconPaperBoard", ClIconPaperBoard);
app.component("ClIconTablerCopy", ClIconTablerCopy);
app.component("ClIconTablerInfoFilled", ClIconTablerInfoFilled);
app.component("ClIconTablerLogout", ClIconTablerLogout);
app.component("ClIconTablerLifebuoy", ClIconTablerLifebuoy);
app.component("ClIconTablerAlertTriangle", ClIconTablerAlertTriangle);
app.component("ClIconTablerFishHook", ClIconTablerFishHook);
app.component("ClIconTablerSchool", ClIconTablerSchool);
app.component("ClIconTablerArchive", ClIconTablerArchive);
app.component("ClIconTablerInfoCircle", ClIconTablerInfoCircle);
app.component("ClIconTablerMailCheck", ClIconTablerMailCheck);
app.component("ClIconTablerMail", ClIconTablerMail);
app.component("ClIconTableCheck", ClIconTableCheck);
app.component("ClIconTablerWorldCheck", ClIconTablerWorldCheck);
app.component("ClIconTablerTable", ClIconTablerTable);
app.component("ClIconTablerX", ClIconTablerX);
app.component("ClIconTablerSearch", ClIconTablerSearch);
app.component("ClIconTablerChevronDown", ClIconTablerChevronDown);
app.component("ClIconTablerExternalLink", ClIconTablerExternalLink);
app.component("ClIconTablerFileTypePdf", ClIconTablerFileTypePdf);
app.component("ClIconTablerFile", ClIconTablerFile);
app.component("ClIconTablerDownload", ClIconTablerDownload);
app.component("ClIconTablerSparklesHorizontal", ClIconTablerSparklesHorizontal);
app.component("ClIconTablerAlertCircleFilled", ClIconTablerAlertCircleFilled);
app.component("ClIconTablerCircleCheckFilled", ClIconTablerCircleCheckFilled);

app.mount("#app");

export default {};
