<template>
  <Teleport to="body">
    <div
      class="tw-absolute tw-right-4 tw-top-2 tw-z-[55] tw-flex tw-flex-col-reverse tw-gap-2"
    >
      <cl-toast
        v-for="toast in toasts"
        :id="toast.id"
        :key="toast.id"
        :title="toast.title"
        :message="toast.message"
        :variant="toast.variant"
        @on-close="closeToast(toast)"
      />
    </div>
  </Teleport>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Toasts",
  computed: mapGetters("toast", ["toasts"]),
  methods: {
    ...mapActions("toast", ["closeToast"]),
  },
};
</script>
