import resolutionHistory from "./resolutionHistory";
import targetedUsers from "./targetedUsers";
import incidents from "./incidents";
import incidentSummary from "./incidentSummary";
import linkLock from "./linkLock";
import remediation from "./remediation";
import notifications from "./notifications";
import exploitedDomains from "./exploitedDomains";
import antiSpoofing from "./antiSpoofing";
import graymail from "./graymail";

export default {
  incidents,
  incidentSummary,
  resolutionHistory,
  targetedUsers,
  linkLock,
  remediation,
  notifications,
  exploitedDomains,
  antiSpoofing,
  graymail,
};
