import { $http } from "@/plugins/api";
import { useFlag } from "@/composables/useFlags/useFlag";

const { isFeatureAvailable: isIncidentsV3Available } = useFlag("INCIDENTS_V3");

const state = {
  targetedUsers: [],
};

const getters = {
  targetedUsers: (state) => state.targetedUsers,
};

const actions = {
  async getTargetedUsers({ commit }, { globalOrganisationID, params }) {
    const filterParams = new URLSearchParams(params);

    if (isIncidentsV3Available.value) {
      const res = await $http.get(
        `${
          import.meta.env.VITE_API_CLIENT_V3
        }/history/globalOrganisationID/${globalOrganisationID}/stats/targets?${filterParams.toString()}`,
      );

      commit("setTargetedUsers", res.data.data);
    } else {
      const res = await $http.get(
        `${
          import.meta.env.VITE_API_CLIENT
        }/pt/history/globalOrganisationID/${globalOrganisationID}/stats/targets?${filterParams.toString()}`,
      );

      commit("setTargetedUsers", res.data.data);
    }
  },
};

const mutations = {
  setTargetedUsers: (state, data) => (state.targetedUsers = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
